import React from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { palettes } from '../../../config';
import { ValidatedVoucher } from '../../../hooks/useVoucherValidation';

interface MobileBreakDownProps {
	total: number;
	deliveryFee: number;
	amountToBePaid: number;
	exchangeRate: number;
	currencySymbol: string;
	voucher?: {
		validatedVoucher: ValidatedVoucher | null;
		discountedAmount: number | null;
	};
}

const MobileBreakDown: React.FC<MobileBreakDownProps> = ({
	total,
	deliveryFee,
	amountToBePaid,
	exchangeRate,
	currencySymbol,
	voucher,
}) => {
	const { t } = useTranslation();
	const currency = currencySymbol || 'EUR';
	const rate = exchangeRate || 1;

	// If voucher is applied
	const storedVoucher = voucher?.validatedVoucher;
	const displayedDiscountedAmount = voucher?.discountedAmount;

	return (
		<View style={{ display: 'flex', marginTop: 50 }}>
			<View
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
				}}
			>
				<Text style={{ fontWeight: 'bold' }}>Sub-Total</Text>
				<Text>
					{getSymbolFromCurrency(currency)}
					{(total * rate).toFixed(2)}
				</Text>
			</View>

			{/* Voucher discount row - only shown when voucher is applied */}
			{storedVoucher && (
				<View
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						marginTop: 10,
					}}
				>
					<Text
						style={{ fontWeight: 'bold', color: palettes.green[0] }}
					>
						Voucher discount ({storedVoucher.discountPercentage}%):
					</Text>
					<Text style={{ color: palettes.green[0] }}>
						-{getSymbolFromCurrency(currency)}
						{(
							((total * storedVoucher.discountPercentage) / 100) *
							rate
						).toFixed(2)}
					</Text>
				</View>
			)}

			<View
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
					marginTop: storedVoucher ? 10 : 0,
					marginBottom: 20,
				}}
			>
				<Text style={{ fontWeight: 'bold' }}>Delivery Fee</Text>
				<Text>
					{getSymbolFromCurrency(currency)}
					{((deliveryFee || 0) * rate).toFixed(2)}
				</Text>
			</View>

			<View
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
					borderTopWidth: 1,
					borderTopColor: palettes.grey[5],
					paddingTop: 10,
				}}
			>
				<Text style={{ fontWeight: 'bold' }}>
					{t('Amount to be Paid')}
				</Text>
				<Text>
					{getSymbolFromCurrency(currency)}
					{storedVoucher && displayedDiscountedAmount != null
						? (displayedDiscountedAmount * rate).toFixed(2)
						: ((amountToBePaid || 0) * rate).toFixed(2)}
				</Text>
			</View>
		</View>
	);
};

export default MobileBreakDown;
