import React, { useEffect, useState } from 'react';
import {
	Image,
	Linking,
	Platform,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Checkbox, Input, Label } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Picker } from '@react-native-picker/picker';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useMutation, useQuery } from '@apollo/client';
import {
	ATTACH_CARD_TO_USER,
	CREATE_CARD,
} from '../../../graphql/stripe/mutations';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { LIST_CARDS } from '../../../graphql/stripe/queries';
import { getCountryValueFromLabel } from '../../../utils/getCountryValueFromLabel';
import countryNamesWithCodes from '../../../utils/countryNamesWithCodes';
import { monthsByNumber } from '../../../utils/monthsUtils';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	setCreateCardModalState,
	setIsSaveCard,
} from '../../../redux/getCardsSlice';
import PaymentModeImage from '../../../assets/paiement_sécurisé_par_stripe.png';
import { useTranslation } from 'react-i18next';
import { setPayingCardDetails } from '../../../redux/oneStopCheckoutSlice';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { CREATE_ADDRESS_BOOK } from '../../../graphql/profile/mutations';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/exportedTypes';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useBrandAllowedCurrency } from '../../../hooks/useBrandAllowedCurrency';
import MobileBreakDown from '../../common/checkout/MobileBreakDown';

const schema = Yup.object({
	cardName: Yup.string(),
	expiryMonth: Yup.string().required('Required'),
	expiryYear: Yup.string().required('Required'),
	cardNumber: Yup.string().required('Required'),
	cvv: Yup.string().required('Required'),
});

const schemaIsVoucher = Yup.object({
	cardName: Yup.string(),
	expiryMonth: Yup.string(),
	expiryYear: Yup.string(),
	cardNumber: Yup.string(),
	cvv: Yup.string(),
});

type TInitialValues = Yup.InferType<typeof schema>;

const initialValues: TInitialValues = {
	cardName: '',
	expiryMonth: '',
	expiryYear: '',
	cardNumber: '',
	cvv: '',
};

const AddCardForm = (props: {
	onSubmit: (
		cardName: string,
		expiryMonth: string,
		expiryYear: string,
		cardNumber: string,
		// address: string,
		// country: string,
		// zipCode: string,
		// city: string,
		// state: string,
		cvv: string
	) => void;
	orderNow: (args: any) => void;
	onCheckout?: boolean;
	chargingLoading?: boolean;
	isShowCardSection?: boolean;
	selectedCard?: string;
	showForm?: boolean;
	total?: number;
	deliveryFee?: number;
	maxOrderForDelivery?: number;
	amountToBePaid?: number;
}) => {
	const handleSubmit = (values: {
		cardName: string;
		expiryMonth: string;
		expiryYear: string;
		cardNumber: string;
		// address: string;
		// country: string;
		// zipCode: string;
		// city: string;
		// state: string;
		cvv: string;
	}) => {
		props.onSubmit(
			values.cardName,
			values.expiryMonth,
			values.expiryYear,
			values.cardNumber,
			// values.address,
			// values.country,
			// values.zipCode,
			// values.city,
			// values.state,
			values.cvv
		);
	};

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { setAlert } = useSetAlert();
	const [cardName, setCardName] = useState('');
	const [exp_month, setExp_month] = useState(0);
	const [exp_year, setExp_year] = useState(0);
	const [cardNumber, setCardNumber] = useState('');
	const [address, setAddress] = useState('');
	const [country, setCountry] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [city, setCity] = useState('');
	const [cvv, setCvv] = useState('');
	const [state, setState] = useState('');
	const [isAcceptTermsAndCondition, setIsAcceptTermsAndCondition] =
		useState(false);

	const userID = useAppSelector(state => state.getStripeId.stripeID);
	const { isSaveAddressCard } = useAppSelector(state => state.userCards);
	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);

	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const { visitorCartStore } = useAppSelector(state => state.user);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];
	const billingFullAddress = useAppSelector(
		state => state.auth.loggedInUserDetails?.personalInformation?.address
	);
	const {
		validatedVoucher: storedVoucher,
		discountedAmount: storedDiscountedAmount,
	} = useAppSelector(state => state.voucher);

	const displayedDiscountedAmount = storedVoucher
		? storedDiscountedAmount
		: props?.amountToBePaid || 0;

	const onlyBrandInCart = usersCart?.[0]?.eventId?.affiliatedBrands?.[0];

	const { findDefaultCountry, findMyCountry, findMyCurrency } =
		useBrandAllowedCurrency({ brand: onlyBrandInCart });

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const marginBottom = isLessThanDesktopBase ? 20 : 10;

	const [createCard, { data, loading }] = useMutation(CREATE_CARD, {
		variables: {
			args: {
				args_card: {
					number: cardNumber,
					exp_month: exp_month,
					exp_year: exp_year,
					cvc: cvv,
				},
				args_billingDetails: {
					city: billingFullAddress?.city,
					country: billingFullAddress?.countryCode?.toUpperCase(),
					line1: billingFullAddress?.address1,
					postal_code: billingFullAddress?.zipCode,
					// state: state,
				},
				args_userDetails: {
					name: cardName,
				},
			},
		},
		onCompleted: data => {
			if (data.createCard.data && data.createCard.success) {
				attachPaymentToCustomer({
					variables: {
						args: {
							id: data?.createCard?.data?.id,
							customerId: userID,
						},
					},
				});
				// setAlert(t('Card created successfully'), 'normal');
			} else {
				dispatch(setCreateCardModalState());
				setAlert(t('Unable to create card'), 'danger');
			}
		},
		onError: () => {
			dispatch(setCreateCardModalState());
			setAlert(t('Unable to create card'), 'danger');
		},
	});

	const [attachPaymentToCustomer, { loading: attachingCard, error }] =
		useMutation(ATTACH_CARD_TO_USER, {
			refetchQueries: [
				{
					query: LIST_CARDS,
					variables: {
						args: {
							id: stripeId_,
						},
					},
				},
			],
			onCompleted: data => {
				if (data.attachPaymentToCustomer?.success) {
					dispatch(setCreateCardModalState());
					setAlert(t('Card created successfully'), 'normal');
				} else {
					dispatch(setCreateCardModalState());
				}
			},
		});
	useEffect(() => {
		if (error) {
			setAlert(error.message, 'normal');
		}
	}, [error]);

	const currentYear = new Date().getFullYear();

	const settingValues = (val: any) => {
		setCardName(val.cardName);
		setAddress(val.address);
		setCardNumber(val.cardNumber);
		setCity(val.city);
		setState(val.state);
		setZipCode(val.zipCode);
		setExp_month(
			monthsByNumber[val.expiryMonth as keyof typeof monthsByNumber]
		);
		setExp_year(parseInt(val.expiryYear));
		setCountry(
			getCountryValueFromLabel(val.country)?.toUpperCase() ??
				'Not selected'
		);
		setCvv(val.cvv);
	};

	const [btnValue, setBtnValue] = useState<'Save' | 'Saved'>('Save');
	const [isSaveAddress, setIsSaveAddress] = useState(false);
	const handleSaveCardOnCheckout = async (values: TODO) => {
		await dispatch(
			setPayingCardDetails({
				cardNo: values.cardNumber,
				cvv: values.cvv,
				expMonth: values.expiryMonth,
				name: values.cardName,
				expYear: values.expiryYear,
			})
		);
		props.orderNow({
			cardNo: values.cardNumber,
			cvv: values.cvv,
			expMonth: values.expiryMonth,
			name: values.cardName,
			expYear: values.expiryYear,
		});
		// setBtnValue('Saved');
	};

	const payingAddressDeets = useAppSelector(
		state => state.oneStopCheckout.address
	);
	const [createAddressBook, { loading: createAddressBookLoading }] =
		useMutation<TCreateAddressBookResponse, TCreateAddressBookInputs>(
			CREATE_ADDRESS_BOOK
		);
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const saveForLaterUse = async () => {
		const addressArgs = {
			address: payingAddressDeets.address,
			city: payingAddressDeets.city,
			countery: payingAddressDeets.countery,
			name: payingAddressDeets.name || loggedInUserDetails?.username,
			zipCode: payingAddressDeets.zipCode,
			phoneNumber: payingAddressDeets.phoneNumber,
		};

		if (isSaveAddressCard) {
			await createAddressBook({
				variables: {
					args: addressArgs as any,
				},
			});
		}

		// if (createdCardId) {
		// 	await attachPaymentToCustomer({
		// 		variables: {
		// 			args: {
		// 				id: createdCardId,
		// 				customerId: userId,
		// 			},
		// 		},
		// 	});
		// }
	};

	useEffect(() => {
		dispatch(setIsSaveCard(isSaveAddress));
	}, [isSaveAddress]);

	const handleSaveCardInModal = async () => {
		await createCard();
	};

	const navigation = useNavigation<TUseNavigation>();

	const primary = usePrimaryColors();
	if (loading) {
		return <div>{t('Creating Card...')}</div>;
	}
	if (attachingCard) {
		return <div>{t('Attaching card to your account...')}</div>;
	} else {
		return (
			<Formik
				initialValues={initialValues}
				validationSchema={
					props.isShowCardSection && props.showForm
						? schema
						: schemaIsVoucher
				}
				onSubmit={async values => {
					await settingValues(values);
					props.onCheckout
						? handleSaveCardOnCheckout(values)
						: handleSaveCardInModal();
					// await attachPaymentToCustomer();
					saveForLaterUse();
				}}
				// handleSubmit(values)
				// }}
			>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					dirty,
					isValid,
					errors,
					touched,
					setFieldValue,
				}) => (
					<>
						{props.isShowCardSection && props.showForm && (
							<View
								style={[
									!isLessThanDesktopBase &&
										(props.onCheckout
											? { width: '70%' }
											: { width: '100%' }),
								]}
							>
								{/* <Input
									value={values.cardName}
									style={[styles.input]}
									containerStyles={{
										marginBottom:
											getResponsiveStyle(marginBottom),
									}}
									onChangeText={handleChange('cardName')}
									onBlur={handleBlur('cardName')}
									label={`${t('Name on Card*')}`}
									error={
										touched.cardName ? errors.cardName : ''
									}
									// placeholder="Enter bank name"
								/> */}
								<Input
									value={values.cardNumber}
									style={[styles.input]}
									placeholder="1234 1234 1234 1234"
									containerStyles={{
										marginBottom:
											getResponsiveStyle(marginBottom),
									}}
									onChangeText={handleChange('cardNumber')}
									onBlur={handleBlur('cardNumber')}
									label={`${t('Credit Card Number*')}`}
									error={
										touched.cardNumber
											? errors.cardNumber
											: ''
									}
								/>
								<Label
									error={
										touched.expiryYear ||
										touched.expiryMonth
											? errors.expiryYear
											: ''
									}
									label={`${t('Expiration Date')}*`}
								/>
								<View style={styles.joinedContainerWithGap}>
									<Picker
										selectedValue={values.expiryMonth}
										onValueChange={itemValue =>
											setFieldValue(
												'expiryMonth',
												itemValue
											)
										}
										style={[
											styles.dropdownContainer,
											{
												flexBasis: '50%',
											},
											Platform.OS === 'web' &&
											!touched.expiryMonth &&
											!errors.expiryMonth
												? styleFocusOutline(primary)
												: styleErrorOutline(),

											// styleInput(decoration),
											!!errors.expiryMonth &&
												touched.expiryMonth &&
												styles.inputError,
										]}
										dropdownIconColor={palettes.grey[1]}
										onBlur={handleBlur('expiryMonth')}
										itemStyle={styles.dropdownItem}
									>
										<Picker.Item
											label={t('Month')}
											value=""
											enabled={false}
										/>
										<Picker.Item
											label={`${t('January')}`}
											value="January"
										/>
										<Picker.Item
											label={`${t('February')}`}
											value="February"
										/>
										<Picker.Item
											label={`${t('March')}`}
											value="March"
										/>
										<Picker.Item
											label={`${t('April')}`}
											value="April"
										/>
										<Picker.Item
											label={`${t('May')}`}
											value="May"
										/>
										<Picker.Item
											label={`${t('June')}`}
											value="June"
										/>
										<Picker.Item
											label={`${t('July')}`}
											value="July"
										/>
										<Picker.Item
											label={`${t('August')}`}
											value="August"
										/>
										<Picker.Item
											label={`${t('September')}`}
											value="September"
										/>
										<Picker.Item
											label={`${t('October')}`}
											value="October"
										/>
										<Picker.Item
											label={`${t('November')}`}
											value="November"
										/>
										<Picker.Item
											label={`${t('December')}`}
											value="December"
										/>
									</Picker>
									<Picker
										selectedValue={values.expiryYear}
										onValueChange={itemValue =>
											setFieldValue(
												'expiryYear',
												itemValue
											)
										}
										style={[
											styles.dropdownContainer,
											{
												flexBasis: '50%',
											},
											Platform.OS === 'web' &&
											!touched.expiryYear &&
											!errors.expiryYear
												? styleFocusOutline(primary)
												: styleErrorOutline(),

											// styleInput(decoration),
											!!errors.expiryYear &&
												touched.expiryYear &&
												styles.inputError,
										]}
										dropdownIconColor={palettes.grey[1]}
										onBlur={handleBlur('expiryYear')}
										itemStyle={styles.dropdownItem}
									>
										<Picker.Item
											label={t('Year')}
											value=""
											enabled={false}
										/>
										<Picker.Item
											label={(currentYear + 5).toString()}
											value={(currentYear + 5).toString()}
										/>
										<Picker.Item
											label={(currentYear + 4).toString()}
											value={(currentYear + 4).toString()}
										/>
										<Picker.Item
											label={(currentYear + 3).toString()}
											value={(currentYear + 3).toString()}
										/>
										<Picker.Item
											label={(currentYear + 2).toString()}
											value={(currentYear + 2).toString()}
										/>
										<Picker.Item
											label={(currentYear + 1).toString()}
											value={(currentYear + 1).toString()}
										/>
										<Picker.Item
											label={currentYear.toString()}
											value={currentYear.toString()}
										/>
									</Picker>
								</View>

								<Input
									value={values.cvv}
									style={[styles.input]}
									placeholder="123"
									containerStyles={{
										marginBottom:
											getResponsiveStyle(marginBottom),
									}}
									onChangeText={handleChange('cvv')}
									onBlur={handleBlur('cvv')}
									label={`${t('CVV*')}`}
									error={touched.cvv ? errors.cvv : ''}
								/>
							</View>
						)}
						{props.isShowCardSection && props.onCheckout && (
							<Checkbox
								// hintText={getAgreementStatement()}
								containerStyles={{
									marginTop: getResponsiveStyle(
										14,
										'spacing'
									),
								}}
								hintText={`${t(
									'Accept general conditions of use and sale'
								)}*`}
								isLink
								onPressLink={() =>
									Linking.openURL(
										'https://www.ocpus.net/cgv/'
									)
								}
								onChange={value =>
									setIsAcceptTermsAndCondition(
										!isAcceptTermsAndCondition
									)
								}
								value={isAcceptTermsAndCondition}
							/>
						)}

						{props.isShowCardSection &&
							props.showForm &&
							props.onCheckout &&
							isUserLoggedIn && (
								<Checkbox
									// hintText={getAgreementStatement()}
									containerStyles={{
										marginTop: getResponsiveStyle(
											14,
											'spacing'
										),
									}}
									hintText={`${t('Save info for later')}`}
									onChange={value =>
										setIsSaveAddress(!isSaveAddress)
									}
									value={isSaveAddress}
								/>
							)}

						<Image
							source={PaymentModeImage}
							style={{
								width: 200,
								height: 100,
							}}
						/>

						{/* {isLessThanDesktopBase && !!props?.total && (
							<View
								style={{ display: 'flex', marginTop: '50px' }}
							>
								<View
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
									}}
								>
									<Text style={{ fontWeight: 'bold' }}>
										Sub-Total
									</Text>
									<Text>
										{getSymbolFromCurrency(
											(findMyCurrency?.currency ||
												findDefaultCountry?.currency) as string
										)}
										{(props?.total?.toFixed(2) as any) *
											(+findMyCountry?.exchangeRate || 1)}
									</Text>
								</View>

								<View
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										marginBottom: '20px',
									}}
								>
									<Text style={{ fontWeight: 'bold' }}>
										Delivery Fee
									</Text>
									<Text>
										{getSymbolFromCurrency(
											(findMyCurrency?.currency ||
												findDefaultCountry?.currency) as string
										)}
										{props?.total >
										Number(
											(Number(
												props?.maxOrderForDelivery
											)?.toFixed(2) as any) *
												(+findMyCountry?.exchangeRate ||
													1)
										)
											? 0
											: (Number(
													props?.deliveryFee
											  )?.toFixed(2) as any) *
											  (+findMyCountry?.exchangeRate ||
													1)}
									</Text>
								</View>

								<View
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
									}}
								>
									<Text style={{ fontWeight: 'bold' }}>
										{t('Amount to be Paid')}
									</Text>
									<Text>
										{getSymbolFromCurrency(
											(findMyCurrency?.currency ||
												findDefaultCountry?.currency) as string
										)}
										{(props?.amountToBePaid as number) *
											(findMyCountry?.exchangeRate ||
												1) ?? 0}
									</Text>
								</View>
							</View>
						)} */}

						{isLessThanDesktopBase && !!props?.total && (
							<MobileBreakDown
								total={props?.total}
								deliveryFee={props?.deliveryFee || 0}
								amountToBePaid={props?.amountToBePaid || 0}
								exchangeRate={findMyCountry?.exchangeRate || 1}
								currencySymbol={
									(findMyCurrency?.currency ||
										findDefaultCountry?.currency) as string
								}
								voucher={
									storedVoucher
										? {
												validatedVoucher: storedVoucher,
												discountedAmount:
													displayedDiscountedAmount,
										  }
										: undefined
								}
							/>
						)}
						{props.onCheckout ? (
							<View style={styles.buttonContainer}>
								{!isLessThanDesktopBase && (
									<Button
										title={t('Back')}
										variant={'grey-outline'}
										width={getResponsiveStyle(
											40,
											'spacing'
										)}
										outerContainerProps={{
											style: styleLoginButtonOuterContainer(),
										}}
										size="lg"
										onPress={() => {
											navigation.navigate(
												'CheckoutAddress'
											);
										}}
									/>
								)}

								<Button
									title={t('Order now')}
									loading={props.chargingLoading}
									variant={
										(isValid &&
											dirty &&
											!!values.cvv &&
											isAcceptTermsAndCondition &&
											usersCart.length > 0) ||
										(!props.isShowCardSection &&
											usersCart.length > 0) ||
										(props.selectedCard &&
											usersCart.length > 0)
											? 'primary'
											: 'disabled'
									}
									// width={getResponsiveStyle(
									// 	isLessThanDesktopBase ? 130 : 70,
									// 	'spacing'
									// )}
									outerContainerProps={{
										style: [
											styleLoginButtonOuterContainer(),
											{
												width: isLessThanDesktopBase
													? '100%'
													: '35%',
											},
										],
									}}
									size="lg"
									onPress={
										!props.chargingLoading && handleSubmit
									}
								/>
							</View>
						) : (
							<Button
								title={t('Save')}
								variant={'outline'}
								// width={getResponsiveStyle(40, 'spacing')}
								outerContainerProps={{
									style: styleLoginButtonOuterContainer(),
								}}
								size="lg"
								onPress={handleSubmit}
							/>
						)}
					</>
				)}
			</Formik>
		);
	}
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	countryContainer: {
		flexBasis: '50%',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		paddingLeft: getResponsiveStyle(19),
		marginBottom: getResponsiveStyle(15),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	joinedContainer: {
		flexDirection: 'row',

		// width: '100%',
		// flex: 1,
	},
	joinedContainerWithGap: {
		flexDirection: 'row',
		gap: getResponsiveStyle(10),
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});

export default AddCardForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(26),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
