import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATE_VOUCHER_CODE } from '../graphql/vouchers/mutation';
import { useSetAlert } from './useSetAlerts';

export type ValidatedVoucher = {
	_id: string;
	code: string;
	discountPercentage: number;
	brandId: string;
	brandName: string;
	description: string;
	isValid: boolean;
	minimumOrderAmount: number;
	expiryDate: string;
};

export const useVoucherValidation = () => {
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const [validatedVoucher, setValidatedVoucher] =
		useState<ValidatedVoucher | null>(null);
	const [isValidating, setIsValidating] = useState(false);

	const [validateVoucherCode] = useMutation(VALIDATE_VOUCHER_CODE, {
		onCompleted: data => {
			setIsValidating(false);
			if (data?.validateUserVoucher?.success) {
				setValidatedVoucher(data.validateUserVoucher.data);
				setAlert('Voucher applied successfully!', 'normal');
			} else {
				setValidatedVoucher(null);
				setAlert(
					data?.validateUserVoucher?.message ||
						'Invalid voucher code',
					'danger'
				);
			}
		},
		onError: error => {
			setIsValidating(false);
			setValidatedVoucher(null);
			setAlert(error.message || 'Error validating voucher', 'danger');
		},
	});

	const validateVoucher = (code: string, brandId: string) => {
		if (!code.trim()) {
			setAlert('Please enter a voucher code', 'danger');
			return;
		}

		setIsValidating(true);
		validateVoucherCode({
			variables: {
				args: {
					code,
					brandId,
				},
			},
		});
	};

	const clearVoucher = () => {
		setValidatedVoucher(null);
	};

	const calculateDiscountedAmount = (
		originalAmount: number,
		deliveryFee: number = 0
	) => {
		if (!validatedVoucher) return originalAmount;

		// Extract the subtotal (without delivery fee) if deliveryFee is provided
		const subtotal =
			deliveryFee > 0 ? originalAmount - deliveryFee : originalAmount;

		if (subtotal < validatedVoucher.minimumOrderAmount) {
			setAlert(
				`Minimum order amount is ${validatedVoucher.minimumOrderAmount} to apply this voucher`,
				'danger'
			);
			return originalAmount;
		}

		// Apply discount only to the subtotal
		const discountAmount =
			(subtotal * validatedVoucher.discountPercentage) / 100;
		// Return the discounted subtotal + delivery fee
		return subtotal - discountAmount + deliveryFee;
	};

	return {
		validatedVoucher,
		isValidating,
		validateVoucher,
		clearVoucher,
		calculateDiscountedAmount,
	};
};
