import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LIST_CARDS } from '../../graphql/stripe/queries';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useSetAlert } from '../useSetAlerts';
import { useIsSpecificViewportWidth } from '../useIsSpecificViewportWidth';
import { setUserCards, setloadingCards } from '../../redux/getCardsSlice';
import { useGetCart } from '../useGetCart';
import { DELETE_CART } from '../../graphql/cart/mutation';
import { GET_CART } from '../../graphql/cart/queries';
import {
	ATTACH_CARD_TO_USER,
	CHARGE_CUSTOMER,
	CONFIRM_CHARGE,
} from '../../graphql/stripe/mutations';
import { setVouchers } from '../../redux/walletSlice';
import { setVoucherLoading } from '../../redux/loaderSlice';
import { GET_ALL_VOUCHERs } from '../../graphql/vouchers/queries';
import {
	CREATE_ORDER,
	SET_PAYMENT_STATUS,
} from '../../graphql/order/mutations';
import { useOnCompleteOrderWithCard } from '../handleMoneyDistribution/useCardPaymentComplete';
import { setCreatedCardId } from '../../redux/oneStopCheckoutSlice';
import { setVisitorCart, setWasCardUsed } from '../../redux/userSlice';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../types/exportedTypes';
import { UPDATE_VOUCHER } from '../../graphql/vouchers/mutation';
import { getCountryLabelFromValue } from '../../utils/getCountryLabelFromValue';
import { loadStripe } from '@stripe/stripe-js';
import useCreateAnonymousUser from '../../api/auth/useCreatAnonymousUser';
import { stripe_key } from '../../utils/stripeUtil';
import { monthsByNumber } from '../../utils/monthsUtils';
import { useBrandAllowedCurrency } from '../useBrandAllowedCurrency';
import { clearVoucher } from '../../redux/voucherSlice';

export const useCheckOut = ({
	amountToBePaid,
	setAmountToBePaid,
	maxOrderForDeliveryFee,
	deliveryFee,
	Total,
	brandInCartIds,
	items,
	chargingWithoutCardData,
	chargeCustomerWithoutSavingCardFn,
}: any) => {
	const dispatch = useAppDispatch();
	const navigation = useNavigation<TUseNavigation>();

	const { t } = useTranslation();

	const [itemsInCartNotAvailable, setItemsInCartNotAvailable] = useState<
		string[]
	>([]);

	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		state => state.auth
	);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];
	const { validatedVoucher, discountedAmount } = useAppSelector(
		state => state.voucher
	);

	const onlyBrandInCart = usersCart?.[0]?.eventId?.affiliatedBrands?.[0];

	const { findMyCurrency, findMyCountry } = useBrandAllowedCurrency({
		brand: onlyBrandInCart,
	});

	const { selectedVoucher } = useAppSelector(state => state.wallet);
	const eventBoughtFrom = useAppSelector(state => state.user.buyingFromEvent);

	const [cardSelected, setCardSelected] = useState('');

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { setAlert } = useSetAlert();

	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);
	const userAccountType = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const [voucherPaymentSuccessful, setVoucherPaymentSuccessful] =
		useState<boolean>(false);
	const [payNowData, setPayNowData] = useState();
	const [loadingStripe3Ds, setLoadingStripe3Ds] = useState(false);

	const { loading: CardsLoading, data: cardsListed } = useQuery(LIST_CARDS, {
		variables: {
			args: {
				id: stripeId_,
			},
		},
		onCompleted: res => {
			dispatch(setUserCards(res.listCards.data.data));
		},
	});
	const [chargeCustomer, { data: chargingData, loading: chargingLoading }] =
		useMutation(CHARGE_CUSTOMER, {
			errorPolicy: 'all',
			onError: () => {
				setAlert('Unable to Complete Purchase', 'danger');
			},
		});

	const [deleteCart, { loading: deleteCartLoading }] = useMutation(
		DELETE_CART,
		{
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_CART }],
		}
	);

	const { getCart, gettingCartLoading } = useGetCart();

	const [selectedVoucherAmountLeft, setSelectedVoucherAmountLeft] = useState(
		Number(selectedVoucher?.amount)
	);
	const { data: vouchersData, loading: gettingVoucher } = useQuery(
		GET_ALL_VOUCHERs,
		{
			onCompleted: () => {
				dispatch(setVouchers(vouchersData?.getAllVouchers?.data.data));
				dispatch(setVoucherLoading(false));
			},
		}
	);

	const isAnyItemInCartNotAvailable = async () => {
		const itemsNotAvailable: string[] = [];
		setItemsInCartNotAvailable([]);
		const resss = await getCart();
		// await getCart().then((resss) => {
		const cart_: Cart = resss?.data?.getCart?.data?.data?.map(
			(item: any) => item.items
		);
		cart_?.map(product => {
			if (
				product?.productId &&
				Number(product?.productId?.option?.inventory?.quantity) <
					product.quantity
			) {
				setItemsInCartNotAvailable(prev => [
					...prev,
					product?.productId?.associatedProduct?.name,
				]);
				itemsNotAvailable.push(
					product?.productId?.associatedProduct?.name
				);
			}
			if (
				product?.starterkitId &&
				product?.starterkitId?.inventory &&
				Number(product?.starterkitId?.inventory?.quantity) <
					product.quantity
			) {
				setItemsInCartNotAvailable(prev => [
					...prev,
					product?.starterkitId?.name,
				]);
				itemsNotAvailable.push(product?.starterkitId?.name);
			}
		});
		// })
		if (itemsNotAvailable.length > 0) {
			return itemsNotAvailable;
		}
		return false;
	};

	const chargeAmount = (amount: number) => {
		// account for voucher discount if available
		const amountToCharge = validatedVoucher ? discountedAmount! : amount;

		// amount is * 100 because the currency is in the smallest unit, i.e for eur, it's cent, for euros, it's also cent
		const totalAmountToBeCharged = Math.round(
			amountToCharge * +findMyCountry?.exchangeRate * 100
		);

		return totalAmountToBeCharged;
	};

	// CREATING ORDER //
	const [
		createOrder,
		{ loading: createOrderLoading, error: createOrderError },
	] = useMutation(CREATE_ORDER);

	useEffect(() => {
		if (createOrderError) {
			setAlert(`${t('Unable to complete order')}`, 'danger');
		}
	}, [createOrderError]);
	// PAYMENT //

	const [attachPaymentToCustomer] = useMutation(ATTACH_CARD_TO_USER, {
		refetchQueries: [
			{
				query: LIST_CARDS,
				variables: {
					args: {
						id: stripeId_,
					},
				},
			},
		],
	});

	const onCompleteOrderWithCard = useOnCompleteOrderWithCard();

	useEffect(() => {
		setCardSelected('');
	}, []);

	const { isSaveCard } = useAppSelector(state => state.userCards);

	const isShowCardSection = selectedVoucher.amount
		? +selectedVoucher.amount < amountToBePaid || amountToBePaid > 0
			? true
			: false
		: true;

	const [setPaymentStatus, { loading: setPaymentStatusLoading }] =
		useMutation(SET_PAYMENT_STATUS);

	const onChargingCustomerSuccess = (
		data: TODO,
		items: [],
		orderId: string
	) => {
		if (
			(data &&
				data.chargeCustomer?.success === true &&
				data.chargeCustomer?.statusCode === 200) ||
			(data &&
				data.chargeCustomerWithoutSavingCard?.success === true &&
				data.chargeCustomerWithoutSavingCard?.statusCode === 200) ||
			(data &&
				data.confirmCharge?.success === true &&
				data.confirmCharge?.statusCode === 200) ||
			!isShowCardSection
		) {
			const chargeId_ =
				data?.chargeCustomer?.data?.charges?.data[0]?.id ??
				data?.chargeCustomerWithoutSavingCard?.data?.charges?.data[0]
					?.id ??
				data?.confirmCharge?.data?.charges?.data[0]?.id;

			if (selectedVoucher) {
				const amountLeft =
					+selectedVoucher.amount <= amountToBePaid
						? 0
						: +selectedVoucher.amount - (Total + deliveryFee);
				const calculatedAmountLeft = amountLeft < 0 ? 0 : amountLeft;
				const amountSpent =
					+selectedVoucher.amount <= amountToBePaid
						? +selectedVoucher.amount
						: +selectedVoucher.amount - (Total + deliveryFee);
				const voucherUpdateInfo = [
					{
						_id: selectedVoucher.voucherId,
						amountLeft: calculatedAmountLeft,
						amountSpent,
					},
				];
				updateVoucher({
					variables: {
						args: {
							voucherUpdateInfo,
						},
					},
				});
			}

			// Clear any applied voucher after successful payment
			dispatch(clearVoucher());

			if (data.chargeCustomerWithoutSavingCard?.success) {
				dispatch(
					setCreatedCardId(
						data?.chargeCustomerWithoutSavingCard?.data
							?.payment_method
					)
				);

				if (isSaveCard) {
					attachPaymentToCustomer({
						variables: {
							args: {
								id: data?.chargeCustomerWithoutSavingCard?.data
									?.payment_method,
								customerId: stripeId_,
							},
						},
					});
				}
			}

			if (isShowCardSection) {
				onCompleteOrderWithCard({
					chargeId: chargeId_,
					items: items,
					deliveryFee: deliveryFee,
					amountToBePaid: amountToBePaid,
				});
			}

			// createOrder({
			// 	variables: {
			// 		args: {
			// 			items,
			// 			paidWith: 'CARD',
			// 			brand:
			// 				brandCount > 1
			// 					? 'Multiple'
			// 					: brandCount === 1 && brandInCartIds[0],
			// 			eventType: 'LIVE',
			// 			purchasedTotalAmount: Total,
			// 			deliveryFee,
			// 			deliveryAddress: {
			// 				address: payingAddressDeets.address,
			// 				city: payingAddressDeets.city,
			// 				zipCode: payingAddressDeets.zipCode,
			// 				country: getCountryLabelFromValue(
			// 					payingAddressDeets.countery
			// 				),
			// 			},
			// 		},
			// 	},
			// 	onCompleted: data => {
			// 		if (isShowCardSection) {
			// 			onCompleteOrderWithCard({
			// 				chargeId: chargeId_,
			// 				items: data?.createOrder?.data?.items,
			// 				deliveryFee: deliveryFee,
			// 				amountToBePaid: amountToBePaid,
			// 			});
			// 		}
			// 	},
			// });
			deleteCart();
			dispatch(setVisitorCart([]));
			dispatch(setWasCardUsed(true));
		} else {
			setPaymentStatus({
				variables: {
					orderId,
					paymentStatus: 'FAILED',
				},
			});
			setAlert(t('Unable to complete payment'), 'danger');
		}
	};

	const [
		confirmPayment,
		{ data: confirmChargeData, loading: confirmChargeLoading },
	] = useMutation(CONFIRM_CHARGE);

	// CHARGING CUSTOMER
	const chargingCustomer = (
		cardSelected: string,
		data: any,
		orderId: string,
		items: [],
		anonymousUserId?: string
	) => {
		if (cardSelected) {
			chargeCustomer({
				variables: {
					args: {
						amount: chargeAmount(amountToBePaid),
						currency: findMyCurrency?.currency,
						cardId: cardSelected,
						customerId: stripeId_,
						orderId: orderId,
						associatedUser: isUserLoggedIn
							? loggedInUserDetails?._id
							: null,
						associatedAnonymousUser: !isUserLoggedIn
							? anonymousUserId
							: null,
					},
				},
				onCompleted: async data => {
					if (
						data?.chargeCustomer?.message === 'Charge not completed'
					) {
						setLoadingStripe3Ds(true);
						const stripe = await loadStripe(stripe_key);
						await stripe?.handleCardAction(
							data?.chargeCustomer?.data?.client_secret
						);
						setLoadingStripe3Ds(false);
						confirmPayment({
							variables: {
								args: {
									paymentIntentKey:
										data?.chargeCustomer?.data?.id,
								},
							},
							onCompleted: async res => {
								if (res?.confirmCharge?.success) {
									await onChargingCustomerSuccess(
										res,
										items,
										orderId
									);
								} else {
									setAlert(
										'Unable to complete payment',
										'danger'
									);
									await setPaymentStatus({
										variables: {
											orderId,
											paymentStatus: 'FAILED',
										},
									});
								}
							},
						});
					} else if (
						data?.chargeCustomer?.message === 'Charge succeeded'
					) {
						await onChargingCustomerSuccess(data, items, orderId);
					} else {
						setAlert('Unable to complete payment', 'danger');
					}
				},
				onError: async () => {
					await setPaymentStatus({
						variables: {
							orderId,
							paymentStatus: 'FAILED',
						},
					});
				},
			});
		} else {
			chargeCustomerWithoutSavingCardFn({
				args: {
					amount: chargeAmount(amountToBePaid),
					currency: findMyCurrency?.currency,
					orderId,
					associatedUser: isUserLoggedIn
						? loggedInUserDetails?._id
						: null,
					associatedAnonymousUser: !isUserLoggedIn
						? anonymousUserId
						: null,
					cardArgs: {
						number: payingCardDeets.cardNo || data?.cardNo,
						exp_month:
							monthsByNumber[
								(payingCardDeets.expMonth as keyof typeof monthsByNumber) ||
									data?.expMonth
							],
						exp_year:
							Number(payingCardDeets.expYear) ||
							Number(data?.expYear),
						cvc: payingCardDeets.cvv || data?.cvv,
					},
					userDetailsArgs: {
						name: payingCardDeets.name || data?.name,
					},
				},
				orderId,
				items,
			});
		}
		// }
	};
	const { createAnonymousUser } = useCreateAnonymousUser({
		onCompleted: data => {
			payNowCard('', payNowData, data?.createAnonymousUser?.data?._id);
		},
	});
	const handleCreateAnonymousUser = (
		cardSelected?: string,
		data?: any,
		anonymousUserId?: string
	) => {
		createAnonymousUser({
			anonymousUserData: {
				email: payingAddressDeets.email,
				isCreateStripeUser: true,
				personalInformation: {
					firstName: payingAddressDeets.firstName,
					lastName: payingAddressDeets.lastName,
					phoneNumber: payingAddressDeets.phoneNumber,
				},
			},
		});
		setPayNowData(data);
	};

	// ----  PAYING WITH A SAVED CARD ONLY  ------
	const payNowCard = async (
		cardSelected?: string,
		data?: any,
		anonymousUserId?: string
	) => {
		const res = await isAnyItemInCartNotAvailable();
		if (res) {
			setAlert(
				t('Items') +
					' : ' +
					res.toString() +
					' ' +
					t('are no longer available'),
				'danger'
			);
		} else {
			// Calculate the discounted total if a voucher is applied
			const discountedTotal = validatedVoucher
				? Total - (Total * validatedVoucher.discountPercentage) / 100
				: Total;

			createOrder({
				variables: {
					args: {
						items,
						associatedUser: isUserLoggedIn
							? loggedInUserDetails?._id
							: null,
						associatedAnonymousUser: !isUserLoggedIn
							? anonymousUserId
							: null,
						paidWith: 'CARD',
						brands: brandInCartIds,
						eventType: 'LIVE',
						orderPaymentStatus: 'CHECKOUT',
						purchasedTotalAmount: discountedTotal, // Use the discounted total
						deliveryFee,
						voucherCode: validatedVoucher?.code,
						voucherDiscountPercentage:
							validatedVoucher?.discountPercentage,
						deliveryInfo: {
							name: payingAddressDeets?.name,
							phoneNumber: payingAddressDeets?.phoneNumber,
							emailAddress: isUserLoggedIn
								? loggedInUserDetails?.email
								: payingAddressDeets.email,
							deliveryAddress: {
								address: payingAddressDeets.address,
								city: payingAddressDeets.city,
								zipCode: payingAddressDeets.zipCode,
								country: getCountryLabelFromValue(
									payingAddressDeets.countery
								),
							},
						},
					},
				},
				onCompleted: async res => {
					// await setOrderItems(res?.createOrder?.data?.items)
					const orderId = res?.createOrder?.data?.orderId;
					const items = res?.createOrder?.data?.items;
					if (orderId) {
						chargingCustomer(
							cardSelected as string,
							data,
							orderId,
							items,
							anonymousUserId
						);
					} else {
						setAlert(t('Could not place order'), 'normal');
					}
					// if (isShowCardSection) {
					// 	onCompleteOrderWithCard({
					// 		chargeId: chargeId_,
					// 		items: data?.createOrder?.data?.items,
					// 		deliveryFee: deliveryFee,
					// 		amountToBePaid: amountToBePaid,
					// 	});
					// }
				},
			});
		}

		// DO NOT UNCOMMENT
		// chargingCustomer(cardSelected, data);
	};

	const [updateVoucher, { loading: payingWithVouchersLoading }] =
		useMutation(UPDATE_VOUCHER);

	// ----  PAYING WITH VOUCHER ONLY  ------
	const updatingPayingVouchers = async () => {
		const res = await isAnyItemInCartNotAvailable();
		if (res) {
			setAlert(
				t('Items') +
					' : ' +
					res.toString() +
					' ' +
					t('are no longer available'),
				'danger'
			);
		} else {
			// Calculate the discounted total if a voucher is applied
			const discountedTotal = validatedVoucher
				? Total - (Total * validatedVoucher.discountPercentage) / 100
				: Total;

			await createOrder({
				variables: {
					args: {
						items,
						associatedUser: isUserLoggedIn
							? loggedInUserDetails?._id
							: null,
						associatedAnonymousUser: null,
						paidWith: 'VOUCHER',
						orderPaymentStatus: 'CHECKOUT',
						brands: brandInCartIds,
						eventType: 'LIVE',
						purchasedTotalAmount: discountedTotal, // Use the discounted total
						deliveryFee: deliveryFee,
						voucherCode: validatedVoucher?.code,
						voucherDiscountPercentage:
							validatedVoucher?.discountPercentage,
						deliveryInfo: {
							name: payingAddressDeets?.name,
							phoneNumber: payingAddressDeets?.phoneNumber,
							emailAddress: isUserLoggedIn
								? loggedInUserDetails?.email
								: payingAddressDeets.email,
							deliveryAddress: {
								address: payingAddressDeets.address,
								city: payingAddressDeets.city,
								zipCode: payingAddressDeets.zipCode,
								country: getCountryLabelFromValue(
									payingAddressDeets.countery
								),
							},
						},
					},
				},
				onCompleted: data => {
					if (data?.createOrder?.success) {
						const orderId = data?.createOrder?.data?.orderId;
						let voucherUpdateInfo: TVoucherUpdateInfo[] = [];
						const amountSpent =
							+selectedVoucher.amount <= amountToBePaid
								? +selectedVoucher.amount
								: Total + deliveryFee;
						voucherUpdateInfo.push({
							_id: selectedVoucher.voucherId,
							amountLeft: selectedVoucherAmountLeft,
							amountSpent,
						});
						updateVoucher({
							variables: {
								args: {
									voucherUpdateInfo,
								},
							},
							refetchQueries: [{ query: GET_ALL_VOUCHERs }],
							onCompleted: async data => {
								if (data?.updateVoucher?.success) {
									await setPaymentStatus({
										variables: {
											orderId,
											paymentStatus: 'SUCCESS',
										},
									});
									await deleteCart();
									// Clear voucher after successful voucher payment
									dispatch(clearVoucher());
									await setVoucherPaymentSuccessful(true);
								} else {
									await setPaymentStatus({
										variables: {
											orderId,
											paymentStatus: 'FAILED',
										},
									});
								}
							},
							onError: async () => {
								await setPaymentStatus({
									variables: {
										orderId,
										paymentStatus: 'FAILED',
									},
								});
							},
						});
					}
				},
			});
		}

		// let voucherUpdateInfo: TVoucherUpdateInfo[] = [];
		// voucherUpdateInfo.push({
		// 	_id: selectedVoucher.voucherId,
		// 	amountLeft: selectedVoucherAmountLeft,
		// });
		// // });
		// updateVoucher({
		// 	variables: {
		// 		args: {
		// 			voucherUpdateInfo,
		// 		},
		// 	},
		// 	refetchQueries: [{ query: GET_ALL_VOUCHERs }],
		// });
		// // order -status-pending
		// // stripe-remove- money
		// // if error
		// // status fails
		// // status success{
		// // 	send email
		// // }
		// await deleteCart();
		// await setVoucherPaymentSuccessful(true);
		// await createOrder({
		// 	variables: {
		// 		args: {
		// 			items,
		// 			paidWith: 'VOUCHER',
		// 			brand:
		// 				brandCount > 1
		// 					? 'Multiple'
		// 					: brandCount === 1 && brandInCartIds[0],
		// 			eventType: 'LIVE',
		// 			purchasedTotalAmount: Total,
		// 			deliveryFee: deliveryFee,
		// 			deliveryAddress: {
		// 				address: payingAddressDeets.address,
		// 				city: payingAddressDeets.city,
		// 				zipCode: payingAddressDeets.zipCode,
		// 				country: getCountryLabelFromValue(
		// 					payingAddressDeets.countery
		// 				),
		// 			},
		// 		},
		// 	},
		// });
	};

	const payingAddressDeets = useAppSelector(
		state => state.oneStopCheckout.address
	);
	const payingCardDeets = useAppSelector(state => state.oneStopCheckout.card);

	// ----  PAYING WITH CARD AND VOUCHER  ------

	const payingWithVoucherAndCard = async (data: any) => {
		payNowCard(cardSelected, data);
	};

	useEffect(() => {
		chargingData?.chargeCustomer?.success === false ||
			chargingWithoutCardData?.chargeCustomerWithoutSavingCard
				?.success === false ||
			confirmChargeData?.confirmCharge?.success === false;
	}, []);

	useEffect(() => {
		if (
			(chargingData?.chargeCustomer?.success === true &&
				chargingData?.chargeCustomer?.statusCode === 200) ||
			voucherPaymentSuccessful ||
			(chargingWithoutCardData?.chargeCustomerWithoutSavingCard
				?.success === true &&
				chargingWithoutCardData?.chargeCustomerWithoutSavingCard
					?.statusCode === 200) ||
			(confirmChargeData?.confirmCharge?.success === true &&
				confirmChargeData?.confirmCharge?.statusCode === 200) ||
			!isShowCardSection
		) {
			setAlert(t('Payment successful'), 'normal');
			// Clear any applied voucher after successful payment
			dispatch(clearVoucher());
			navigation.navigate('PurchaseComplete');
		}
	}, [
		chargingData,
		voucherPaymentSuccessful,
		chargingWithoutCardData,
		confirmChargeData,
	]);

	useEffect(() => {
		if (CardsLoading) {
			dispatch(setloadingCards(true));
		}

		if (cardsListed) {
			dispatch(setloadingCards(false));
		}
	}, [cardsListed, CardsLoading]);

	useEffect(() => {
		const selectedVoucherAmount = +selectedVoucher.amount;
		if (userAccountType === 'HOST' && selectedVoucherAmount) {
			const updatedAmountWithDelivery =
				Total + deliveryFee > +selectedVoucherAmount
					? Total + deliveryFee - (+selectedVoucherAmount || 0)
					: 0;
			const updatedAmountWithOutDelivery =
				Total > +selectedVoucherAmount
					? Total - (+selectedVoucherAmount || 0)
					: 0;

			if (Total < +maxOrderForDeliveryFee) {
				setAmountToBePaid(updatedAmountWithDelivery);
			} else {
				setAmountToBePaid(updatedAmountWithOutDelivery);
			}
		} else {
			if (Total < +maxOrderForDeliveryFee) {
				setAmountToBePaid(Total + deliveryFee);
			} else {
				setAmountToBePaid(Total);
			}
		}
	}, [Total, deliveryFee, maxOrderForDeliveryFee, selectedVoucher]);

	const handleCreateOrder = async (
		cardId: string,
		data?: any,
		anonymousId?: string
	) => {
		// Calculate the discounted total if a voucher is applied
		const discountedTotal = validatedVoucher
			? Total - (Total * validatedVoucher.discountPercentage) / 100
			: Total;

		await createOrder({
			variables: {
				args: {
					items,
					associatedUser: isUserLoggedIn
						? loggedInUserDetails?._id
						: null,
					associatedAnonymousUser: !isUserLoggedIn
						? anonymousId
						: null,
					paidWith: 'CARD',
					orderPaymentStatus: 'CHECKOUT',
					brands: brandInCartIds,
					eventType: 'LIVE',
					purchasedTotalAmount: discountedTotal, // Use the discounted total
					deliveryFee: deliveryFee,
					// voucherApplied: validatedVoucher ? {
					// 	voucherId: validatedVoucher._id,
					// 	code: validatedVoucher.code,
					// 	discountPercentage: validatedVoucher.discountPercentage,
					// 	originalAmount: Total,
					// 	discountedAmount: Total - (Total * validatedVoucher.discountPercentage / 100),
					// 	deliveryFee: deliveryFee
					// } : null,
					deliveryInfo: {
						name: payingAddressDeets?.name,
						phoneNumber: payingAddressDeets?.phoneNumber,
						emailAddress: isUserLoggedIn
							? loggedInUserDetails?.email
							: payingAddressDeets.email,
						deliveryAddress: {
							address: payingAddressDeets.address,
							city: payingAddressDeets.city,
							zipCode: payingAddressDeets.zipCode,
							country: getCountryLabelFromValue(
								payingAddressDeets.countery
							),
						},
					},
				},
			},
			onCompleted: async (res: any) => {
				const orderId = res?.createOrder?.data?.orderId;
				const orderItems = res?.createOrder?.data?.items;
				if (orderId) {
					chargingCustomer(
						cardId || '',
						data,
						orderId,
						orderItems,
						anonymousId
					);
				} else {
					setAlert(t('Could not place order'), 'normal');
				}
			},
		});
	};

	return {
		eventBoughtFrom,
		selectedVoucher,
		cardSelected,
		stripeId_,
		userAccountType,
		voucherPaymentSuccessful,
		CardsLoading,
		cardsListed,
		chargingData,
		chargingLoading,
		deleteCartLoading,
		gettingCartLoading,
		usersCart,
		isUserLoggedIn,
		selectedVoucherAmountLeft,
		vouchersData,
		gettingVoucher,
		createOrderLoading,
		createOrderError,
		isSaveCard,
		isShowCardSection,
		setPaymentStatusLoading,
		confirmChargeData,
		confirmChargeLoading,
		payNowData,
		payingWithVouchersLoading,
		loadingStripe3Ds,
		setLoadingStripe3Ds,
		updatingPayingVouchers,
		updateVoucher,
		payingWithVoucherAndCard,
		payNowCard,
		createAnonymousUser,
		handleCreateAnonymousUser,
		setPayNowData,
		chargingCustomer,
		confirmPayment,
		onChargingCustomerSuccess,
		setPaymentStatus,
		attachPaymentToCustomer,
		createOrder,
		isAnyItemInCartNotAvailable,
		chargeAmount,
		getCart,
		deleteCart,
		chargeCustomer,
		setSelectedVoucherAmountLeft,
		setVoucherPaymentSuccessful,
		setCardSelected,
		setItemsInCartNotAvailable,
		handleCreateOrder,
	};
};
