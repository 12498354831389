import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Loader } from '../components';
import CartList from '../components/common/desktop-navigation-bar/Cart-List/CartList';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { useGetEvent } from '../hooks/useGetEvent';
import VoucherSection from '../components/profile/Profile-Vouchers/VoucherSection';
import CardSection from '../components/profile/Profile-Wallet/CardSection';
import { palettes } from '../config';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useOneStopCardPayment } from '../hooks/useOneStopCardPayment';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import { setCurrentScreen } from '../redux/uiSlice';
import { useCheckoutCommon } from '../hooks/handleCheckout/useCheckoutCommon';
import { useGetCartItems } from '../hooks/handleCheckout/useGetCartItems';
import { useCheckOut } from '../hooks/handleCheckout/useCheckOut';
import { useBrandAllowedCurrency } from '../hooks/useBrandAllowedCurrency';
import { twoDp } from '../utils/twoDP';

// const styles = StyleSheet.create({
// 	container: {
// 		flex: 1,
// 		flexDirection: 'row',
// 		paddingHorizontal: getResponsiveStyle(16, 'spacing'),
// 		paddingTop: getResponsiveStyle(16, 'spacing'),
// 	},
// 	container_1: {
// 		width: '60%',
// 		paddingHorizontal: getResponsiveStyle(16, 'spacing'),
// 	},
// 	container_2: {
// 		flex: 1,
// 		flexDirection: 'column',
// 		paddingHorizontal: getResponsiveStyle(16, 'spacing'),
// 	},
// 	headerText: {
// 		fontSize: getResponsiveStyle(20),
// 		fontWeight: '700',
// 		marginBottom: getResponsiveStyle(18),
// 	},
// 	section: {
// 		marginTop: getResponsiveStyle(20),
// 	},
// 	sectionContent: {
// 		paddingHorizontal: getResponsiveStyle(16, 'spacing'),
// 	},
// 	totalSection: {
// 		backgroundColor: palettes.grey[5],
// 		borderRadius: 6,
// 		padding: getResponsiveStyle(16, 'spacing'),
// 	},
// 	totalRow: {
// 		flexDirection: 'row',
// 		justifyContent: 'space-between',
// 		marginBottom: getResponsiveStyle(10),
// 	},
// 	totalLabel: {
// 		fontSize: getResponsiveStyle(16),
// 		color: palettes.dark[0],
// 	},
// 	totalAmount: {
// 		fontSize: getResponsiveStyle(16),
// 		fontWeight: '500',
// 		color: palettes.dark[0],
// 	},
// 	totalFinalRow: {
// 		marginTop: getResponsiveStyle(10),
// 		borderTopWidth: 1,
// 		borderTopColor: palettes.grey[2],
// 		paddingTop: getResponsiveStyle(10),
// 	},
// 	totalFinalLabel: {
// 		fontSize: getResponsiveStyle(18),
// 		fontWeight: '700',
// 		color: palettes.dark[0],
// 	},
// 	totalFinalAmount: {
// 		fontSize: getResponsiveStyle(18),
// 		fontWeight: '700',
// 		color: palettes.dark[0],
// 	},
// });

const CheckoutScreen = () => {
	const dispatch = useAppDispatch();
	const { shouldHideScreen } = useHandleUnmount();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setCurrentScreen('/checkout'));
	}, []);

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const { brandInCartIds, brandsInCart, items } = useGetCartItems();

	const {
		Total,
		amountToBePaid,
		deliveryFee,
		maxOrderForDeliveryFee,
		setAmountToBePaid,
	} = useCheckoutCommon({ brandInCartIds, isCheckoutScreen: true });

	const {
		chargeCustomerWithoutSavingCardFn,
		chargingWithoutCardLoading,
		chargingWithoutCardData,
	} = useOneStopCardPayment({
		// onChargingCustomerSuccess,
		// confirmPayment,
		amountToBePaid,
		deliveryFee,
		Total,
		brandInCartIds,
		items,
		maxOrderForDeliveryFee,
		setAmountToBePaid,
	});

	const {
		eventBoughtFrom,
		selectedVoucher,
		cardSelected,
		userAccountType,
		CardsLoading,
		chargingLoading,
		deleteCartLoading,
		gettingCartLoading,
		isUserLoggedIn,
		usersCart,
		gettingVoucher,
		createOrderLoading,
		isShowCardSection,
		setPaymentStatusLoading,
		confirmChargeLoading,
		payingWithVouchersLoading,
		loadingStripe3Ds,
		payingWithVoucherAndCard,
		updatingPayingVouchers,
		payNowCard,
		handleCreateAnonymousUser,
		setSelectedVoucherAmountLeft,
		setCardSelected,
	} = useCheckOut({
		amountToBePaid,
		deliveryFee,
		Total,
		brandInCartIds,
		items,
		maxOrderForDeliveryFee,
		chargingWithoutCardData,
		setAmountToBePaid,
		chargeCustomerWithoutSavingCardFn,
	});

	const onlyBrandInCart = usersCart?.[0]?.eventId?.affiliatedBrands?.[0];

	const { findDefaultCountry, findMyCountry, findMyCurrency } =
		useBrandAllowedCurrency({ brand: onlyBrandInCart });

	const { getEvent } = useGetEvent({ id: eventBoughtFrom });

	useEffect(() => {
		eventBoughtFrom && getEvent();
	}, [eventBoughtFrom]);

	const { validatedVoucher, discountedAmount, originalAmount } =
		useAppSelector(state => state.voucher);

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			{CardsLoading || gettingVoucher ? (
				<Loader />
			) : (
				<>
					<View style={styles.container}>
						<View
							style={
								!isLessThanDesktopBase
									? styles.container_1
									: { width: '100%' }
							}
						>
							{userAccountType === 'HOST' && (
								<VoucherSection
									onCheckout
									brandsInCart={brandsInCart}
									amountToBePaid={amountToBePaid}
									selectedVoucher={selectedVoucher}
									chargeLoading={
										payingWithVouchersLoading ||
										createOrderLoading ||
										setPaymentStatusLoading ||
										deleteCartLoading ||
										gettingCartLoading
									}
									setSelectedVoucherAmountLeft={
										setSelectedVoucherAmountLeft
									}
									payWithVoucher={updatingPayingVouchers}
									total={Total}
									maxOrderForDelivery={
										+maxOrderForDeliveryFee
									}
									deliveryFee={deliveryFee}
								/>
							)}

							{userAccountType === 'HOST' &&
								+selectedVoucher.amount > 0 &&
								amountToBePaid > 0 && (
									<CardSection
										orderNow={data => {
											payingWithVoucherAndCard(data);
										}}
										isShowCardSection={isShowCardSection}
										onCheckout="yes"
										chargingLoading={
											chargingLoading ||
											chargingWithoutCardLoading ||
											createOrderLoading ||
											confirmChargeLoading ||
											loadingStripe3Ds ||
											gettingCartLoading
										}
										amountToBePaid={amountToBePaid}
										selectedCard={cardSelected}
										setSelectedCard={setCardSelected}
										total={Total}
										maxOrderForDelivery={
											+maxOrderForDeliveryFee
										}
										deliveryFee={deliveryFee}
									/>
								)}

							{userAccountType !== 'HOST' && (
								<CardSection
									orderNow={data => {
										if (isUserLoggedIn) {
											payNowCard(cardSelected, data);
										} else {
											handleCreateAnonymousUser(
												cardSelected,
												data
											);
										}
									}}
									isShowCardSection={isShowCardSection}
									onCheckout="yes"
									chargingLoading={
										chargingLoading ||
										chargingWithoutCardLoading ||
										createOrderLoading ||
										confirmChargeLoading ||
										loadingStripe3Ds ||
										gettingCartLoading
									}
									amountToBePaid={
										validatedVoucher
											? discountedAmount!
											: amountToBePaid
									}
									selectedCard={cardSelected}
									setSelectedCard={setCardSelected}
									total={Total}
									maxOrderForDelivery={
										+maxOrderForDeliveryFee
									}
									deliveryFee={deliveryFee}
								/>
							)}
						</View>
						{!isLessThanDesktopBase && (
							<View style={styles.container_2}>
								{usersCart.length > 0 && (
									<>
										<Text style={styles.headerText}>
											{t('My cart')}
										</Text>
										<CartList onCheckout />
									</>
								)}
								<View
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<View style={styles.breakDown}>
										<Text style={styles.subTotalText}>
											{t('Subtotal')}:
										</Text>
										<Text
											style={[
												styles.subtotal,
												{
													fontSize:
														getResponsiveStyle(11),
												},
											]}
										>
											{getSymbolFromCurrency(
												(findMyCurrency?.currency ||
													findDefaultCountry?.currency) as string
											)}
											{twoDp(
												Total *
													(findMyCountry?.exchangeRate ||
														1)
											)}
										</Text>
									</View>

									<View style={styles.breakDown}>
										<Text style={styles.subTotalText}>
											{t('Delivery Fee')}:
										</Text>
										<Text
											style={[
												styles.subtotal,
												{
													fontSize:
														getResponsiveStyle(11),
												},
											]}
										>
											{getSymbolFromCurrency(
												(findMyCurrency?.currency ||
													findDefaultCountry?.currency) as string
											)}
											{Total > +maxOrderForDeliveryFee
												? 0
												: twoDp(
														deliveryFee *
															(findMyCountry?.exchangeRate ||
																1)
												  )}
										</Text>
									</View>

									{validatedVoucher && (
										<View style={styles.breakDown}>
											<Text
												style={[
													styles.subTotalText,
													{
														color: palettes
															.green[0],
													},
												]}
											>
												Voucher discount (
												{
													validatedVoucher.discountPercentage
												}
												%):
											</Text>
											<Text
												style={[
													styles.subtotal,
													{
														fontSize:
															getResponsiveStyle(
																11
															),
														color: palettes
															.green[0],
													},
												]}
											>
												-
												{getSymbolFromCurrency(
													(findMyCurrency?.currency ||
														findDefaultCountry?.currency) as string
												)}
												{twoDp(
													((Total *
														validatedVoucher.discountPercentage) /
														100) *
														(findMyCountry?.exchangeRate ||
															1)
												)}
											</Text>
										</View>
									)}

									<View style={styles.total}>
										<Text style={styles.subTotalText}>
											{isLessThanDesktopBase
												? ''
												: t('Total')}
										</Text>
										<Text style={styles.subtotal}>
											{isLessThanDesktopBase
												? ''
												: `${getSymbolFromCurrency(
														(findMyCurrency?.currency ||
															findDefaultCountry?.currency) as string
												  )}${
														validatedVoucher
															? twoDp(
																	(discountedAmount ||
																		0) *
																		(findMyCountry?.exchangeRate ||
																			1)
															  )
															: twoDp(
																	amountToBePaid *
																		(findMyCountry?.exchangeRate ||
																			1)
															  )
												  }`}
										</Text>
									</View>
								</View>
							</View>
						)}
					</View>
				</>
			)}
		</DesktopTemplate>
	);
};

export default CheckoutScreen;
const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	total: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginTop: '15px',
		paddingTop: '15px',
		justifyContent: 'space-between',
		// marginLeft: '5%',
		borderTopColor: palettes.grey[5],
		height: 1,
		borderTopWidth: 1,
	},
	container_1: {
		width: '57%',
	},
	container_2: {
		borderLeftWidth: 1,
		borderLeftColor: 'whitesmoke',
		// marginRight: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(17),
		width: '40%',
	},
	subtotal: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		color: palettes.dark[2],
		fontWeight: 'bold',
	},
	subTotalText: {
		fontSize: getResponsiveStyle(11),
		lineHeight: getResponsiveStyle(18),
		color: palettes.grey[1],
	},
	breakDown: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(14, 'font'),
	},
	confirm: {
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		borderRadius: 5,
		marginTop: 10,
	},
});
