import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidatedVoucher } from '../hooks/useVoucherValidation';

interface VoucherState {
	validatedVoucher: ValidatedVoucher | null;
	discountedAmount: number | null;
	originalAmount: number | null;
}

const initialState: VoucherState = {
	validatedVoucher: null,
	discountedAmount: null,
	originalAmount: null,
};

export const voucherSlice = createSlice({
	name: 'voucher',
	initialState,
	reducers: {
		setValidatedVoucher: (
			state,
			action: PayloadAction<ValidatedVoucher | null>
		) => {
			state.validatedVoucher = action.payload;
		},
		setDiscountedAmount: (state, action: PayloadAction<number | null>) => {
			state.discountedAmount = action.payload;
		},
		setOriginalAmount: (state, action: PayloadAction<number | null>) => {
			state.originalAmount = action.payload;
		},
		clearVoucher: state => {
			state.validatedVoucher = null;
			state.discountedAmount = null;
			state.originalAmount = null;
		},
	},
});

export const {
	setValidatedVoucher,
	setDiscountedAmount,
	setOriginalAmount,
	clearVoucher,
} = voucherSlice.actions;

export default voucherSlice.reducer;
