import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Pressable, StyleSheet, Text, View } from 'react-native';
import * as Yup from 'yup';
import { Button, Checkbox, Input } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { setIsSaveAddressCard } from '../../../redux/getCardsSlice';
import { setPayingAddressDetails } from '../../../redux/oneStopCheckoutSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import countryNamesWithCodes, {
	getCommunes,
	wilayas,
} from '../../../utils/countryNamesWithCodes';
import { getCountryLabelFromValue } from '../../../utils/getCountryLabelFromValue';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Dropdown from '../../common/dropdown/Dropdown';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useGetSingleBrandLazy } from '../../../api/brands/useGetSingleBrand';
import { useGetCartItems } from '../../../hooks/handleCheckout/useGetCartItems';
import { useBrandAllowedCurrency } from '../../../hooks/useBrandAllowedCurrency';
import { useVoucherValidation } from '../../../hooks/useVoucherValidation';
import {
	clearVoucher,
	setDiscountedAmount,
	setOriginalAmount,
	setValidatedVoucher,
} from '../../../redux/voucherSlice';
import MobileBreakDown from '../../common/checkout/MobileBreakDown';

export type TAddressBookInitialValues = Yup.InferType<typeof schema>;

const schema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	address: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	countery: Yup.string(),
	zipCode: Yup.string().required('Required'),
	phoneNumber: Yup.string().required('Required'),
});

const visitorSchema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	address: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	countery: Yup.string(),
	zipCode: Yup.string().required('Required'),
	phoneNumber: Yup.string().required('Required'),
});

const getInitialValue = (data: any) => {
	const initialValues: Omit<TAddressBook, 'createdBy' | '_id'> = {
		name: '',
		firstName: '',
		lastName: '',
		email: '',
		city: data?.country === 'DZ' ? 'Alger' : '',
		countery: data?.country?.toLowerCase(),
		address: '',
		address2: '',
		zipCode: '',
		phoneNumber: '',
	};
	return initialValues;
};

type Props = {
	onSubmit: (values: Omit<TAddressBook, 'createdBy' | '_id'>) => void;
	loading: boolean;
	isEdit: boolean;
	addressBookToEditDetails?: Omit<TAddressBook, 'createdBy'>;
	onCheckout?: boolean;
	createOrderLoading?: boolean;
	payOnDelivery?: (e?: any) => void;
	onChangeWilaya?: (e?: any) => void;
	onChangeCountry?: (e?: any) => void;
	setName?: (val: string) => void;
	setAddress?: (val: string) => void;
	setCity?: (val: string) => void;
	setZipCode?: (val: string) => void;
	setCountry?: (val: string) => void;
	isFullWidth?: boolean;
	isAddAddress?: boolean;
	isNotSaveForLater?: boolean;
	phoneNumber?: string;
	paymentMode?: string;
	total?: number;
	maxOrderForDelivery?: number;
	deliveryFee?: number;
	amountToBePaid?: number;
};

const AddAddressForm = ({
	payOnDelivery,
	onChangeWilaya,
	onChangeCountry,
	onSubmit,
	createOrderLoading,
	paymentMode,
	loading,
	isEdit,
	addressBookToEditDetails,
	onCheckout,
	isFullWidth,
	isAddAddress,
	isNotSaveForLater,
	total,
	maxOrderForDelivery,
	deliveryFee,
	amountToBePaid,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [btnValue, setBtnValue] = useState<'Save' | 'Saved'>('Save');
	const [country, setCountry] = useState([]);
	const [brandData, setBrandData] = useState<TGetAllBrandsData>();
	const [isSaveAddress, setIsSaveAddress] = useState(false);
	const [isAcceptTermsAndCondition, setIsAcceptTermsAndCondition] =
		useState(false);
	const [voucherCode, setVoucherCode] = useState('');
	const navigation = useNavigation<TUseNavigation>();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const { country: currentCountry } = useAppSelector(state => state.ui);
	const {
		validatedVoucher: storedVoucher,
		discountedAmount: storedDiscountedAmount,
	} = useAppSelector(state => state.voucher);
	const { brandInCartIds } = useGetCartItems();
	const {
		validatedVoucher,
		isValidating,
		validateVoucher,
		calculateDiscountedAmount,
	} = useVoucherValidation();

	const handleSaveAddressOnCheckout = async (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		await dispatch(
			setPayingAddressDetails({
				address: values.address,
				city: values.city,
				countery: values.countery,
				name: `${values?.firstName} ${values?.lastName}`,
				zipCode: values.zipCode,
				phoneNumber: values.phoneNumber,
				email: values?.email,
				firstName: values?.firstName,
				lastName: values?.lastName,
			})
		);
		await dispatch(setIsSaveAddressCard(isSaveAddress));
		if (!isAddAddress) navigation.navigate('Checkout');
	};
	const handleSubmit = async (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		onSubmit(values);
		onCheckout && handleSaveAddressOnCheckout(values);
	};

	const onPressBack = () => {
		navigation.canGoBack()
			? navigation.goBack()
			: navigation.navigate('Home');
	};

	const marginBottom = isLessThanDesktopBase ? 20 : 10;

	const { getSingleBrand } = useGetSingleBrandLazy({
		onCompleted: data => {
			const brandAllowedCountriesData =
				data?.getBrand?.data?.brandAllowedCountries;
			const getCountries = brandAllowedCountriesData?.map(item => {
				const findCountry = countryNamesWithCodes?.find(
					data => data?.value == item?.countryCode
				);
				return findCountry;
			});
			setBrandData(data?.getBrand?.data);
			setCountry((getCountries || []) as any);
		},
	});

	useEffect(() => {
		getSingleBrand(brandInCartIds?.[0]);
	}, []);

	// Add this new useEffect to clear any existing voucher when the component mounts
	useEffect(() => {
		// Clear any existing voucher when first landing on the address page
		dispatch(clearVoucher());
	}, []);

	const { findDefaultCountry, findMyCountry, findMyCurrency } =
		useBrandAllowedCurrency({ brand: brandData as TGetAllBrandsData });

	useEffect(() => {
		if (validatedVoucher) {
			const calculatedAmount = calculateDiscountedAmount(
				amountToBePaid || 0,
				deliveryFee || 0
			);
			dispatch(setValidatedVoucher(validatedVoucher));
			dispatch(setOriginalAmount(amountToBePaid || 0));
			dispatch(setDiscountedAmount(calculatedAmount));
		}
	}, [validatedVoucher, amountToBePaid, deliveryFee]);

	const handleApplyVoucher = () => {
		if (voucherCode.trim()) {
			validateVoucher(voucherCode, brandData?._id as string);
		}
	};

	// Calculate the current displayed amount
	const displayedDiscountedAmount = storedVoucher
		? storedDiscountedAmount
		: amountToBePaid || 0;

	return (
		<Formik
			enableReinitialize
			initialValues={
				isEdit && addressBookToEditDetails
					? addressBookToEditDetails
					: getInitialValue({ country: currentCountry })
			}
			validationSchema={isUserLoggedIn ? schema : visitorSchema}
			onSubmit={values => {
				handleSubmit(values);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				errors,
				touched,
				setFieldValue,
				isValid,
				dirty,
			}) => (
				<>
					<View
						style={[
							{ zIndex: 5000, width: '100%' },
							!isLessThanDesktopBase && {
								width: isFullWidth ? '100%' : '70%',
							},
						]}
					>
						<>
							<Input
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('firstName')}
								onBlur={handleBlur('firstName')}
								label={t('First Name')}
								error={
									touched.firstName ? errors.firstName : ''
								}
							/>
							<Input
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('lastName')}
								onBlur={handleBlur('lastName')}
								label={t('Last Name')}
								error={touched.lastName ? errors.lastName : ''}
							/>
						</>

						{!isUserLoggedIn && (
							<Input
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								onChangeText={handleChange('email')}
								onBlur={handleBlur('email')}
								label={t('Email')}
								error={touched.email ? errors.email : ''}
							/>
						)}

						<Input
							value={values.address}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(marginBottom),
							}}
							onChangeText={handleChange('address')}
							onBlur={handleBlur('address')}
							label={t('Address')}
							error={touched.address ? errors.address : ''}
						/>

						<Input
							value={values.address2}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(marginBottom),
							}}
							onChangeText={handleChange('address2')}
							onBlur={handleBlur('address2')}
							error={touched.address ? errors.address : ''}
						/>
						{onCheckout ? (
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<View style={{ flex: 1, marginRight: 10 }}>
									{currentCountry === 'DZ' ? (
										<Dropdown
											isSearchable
											dropdownPosition="top"
											options={wilayas}
											labelProps={{
												label: 'Wilaya',
											}}
											onChangeValue={itemSelected => {
												setFieldValue(
													'city',
													itemSelected.value
												);
											}}
											values={{
												label: values.city,
												value: values.city ?? '',
											}}
											error={
												touched.city || touched.city
													? errors.city
													: ''
											}
										/>
									) : (
										<Input
											value={values.city}
											containerStyles={{
												marginBottom:
													getResponsiveStyle(10),
											}}
											onChangeText={handleChange('city')}
											onBlur={handleBlur('city')}
											label={t('City')}
											error={
												touched.city ? errors.city : ''
											}
										/>
									)}
								</View>

								<View style={{ flex: 1 }}>
									{currentCountry === 'DZ' ? (
										<Dropdown
											isSearchable
											dropdownPosition="top"
											options={getCommunes(values.city)}
											labelProps={{
												label: 'Commune',
											}}
											onChangeValue={itemSelected => {
												setFieldValue(
													'zipCode',
													itemSelected.value
												);
												onChangeWilaya &&
													onChangeWilaya(values.city);
											}}
											values={{
												label: values.zipCode,
												value: values.zipCode ?? '',
											}}
											error={
												touched.zipCode ||
												touched.zipCode
													? errors.zipCode
													: ''
											}
										/>
									) : (
										<Input
											value={values.zipCode}
											containerStyles={{
												marginBottom:
													getResponsiveStyle(
														marginBottom
													),
											}}
											onChangeText={handleChange(
												'zipCode'
											)}
											onBlur={handleBlur('zipCode')}
											label={t('Zip Code')}
											error={
												touched.zipCode
													? errors.zipCode
													: ''
											}
										/>
									)}
								</View>
							</View>
						) : (
							<>
								<Input
									value={values.city}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(
											marginBottom + 10
										),
									}}
									onChangeText={handleChange('city')}
									onBlur={handleBlur('city')}
									label={t('City')}
									error={touched.city ? errors.city : ''}
								/>

								<Input
									value={values.zipCode}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(
											marginBottom + 10
										),
									}}
									onChangeText={handleChange('zipCode')}
									onBlur={handleBlur('zipCode')}
									label={t('Zip Code')}
									error={
										touched.zipCode ? errors.zipCode : ''
									}
								/>
							</>
						)}

						<Dropdown
							isSearchable
							dropdownPosition="top"
							options={country}
							labelProps={{
								label: t('Country'),
							}}
							onChangeValue={itemSelected => {
								setFieldValue('countery', itemSelected.value);
								onChangeCountry &&
									onChangeCountry(itemSelected.value);
							}}
							values={{
								label: getCountryLabelFromValue(
									values.countery ?? '',
									country
								),
								value: values.countery ?? '',
							}}
							error={
								touched.countery || touched.countery
									? errors.countery
									: ''
							}
						/>
						<Input
							value={values.phoneNumber}
							style={[styles.input]}
							containerStyles={{
								marginTop: getResponsiveStyle(20),
							}}
							onChangeText={handleChange('phoneNumber')}
							onBlur={handleBlur('phoneNumber')}
							label={t('Phone Number')}
							error={
								touched.phoneNumber ? errors.phoneNumber : ''
							}
						/>
						{/* <Picker
							selectedValue={values.country}
							onValueChange={itemValue =>
								setFieldValue('country', itemValue)
							}
							// containerStyles={{
							// 	marginBottom: getResponsiveStyle(35),

							// 	flexBasis: '50%',
							// }}
							style={[
								styles.dropdownContainer,
								{
									marginBottom: getResponsiveStyle(35),
									flexBasis: '50%',
								},
								Platform.OS === 'web' &&
								!touched.country &&
								!errors.country
									? styleFocusOutline(primaryColors)
									: styleErrorOutline(),

								// styleInput(decoration),
								!!errors.country &&
									touched.country &&
									styles.inputError,
							]}
							dropdownIconColor={palettes.grey[1]}
							onBlur={handleBlur('country')}
							itemStyle={styles.dropdownItem}
						>
							<Picker.Item
								label="Country"
								value=""
								enabled={false}
							/>
							{countryNamesWithCodes.map(country => (
								<Picker.Item
									label={country.label}
									value={country.label}
								/>
							))}
						</Picker> */}
						{!isNotSaveForLater && isUserLoggedIn && (
							<Checkbox
								containerStyles={{
									marginTop: getResponsiveStyle(
										14,
										'spacing'
									),
								}}
								hintText={`${t('Save info for later')}`}
								onChange={value =>
									setIsSaveAddress(!isSaveAddress)
								}
								value={isSaveAddress}
							/>
						)}
					</View>

					{onCheckout && (
						<View
							style={{
								marginBottom: getResponsiveStyle(20),
								marginTop: getResponsiveStyle(20),
							}}
						>
							<Text style={styles.sectionTitle}>Voucher</Text>
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: 10,
								}}
							>
								<Input
									value={voucherCode}
									containerStyles={{
										marginBottom: 0,
										flex: 1,
										marginRight: 10,
									}}
									onChangeText={text => setVoucherCode(text)}
									label="Voucher Code (Optional)"
									placeholder="Enter voucher code if you have one"
								/>
								<Button
									title="Apply"
									loading={isValidating}
									variant={
										voucherCode.trim()
											? 'primary'
											: 'disabled'
									}
									onPress={handleApplyVoucher}
									outerContainerProps={{
										style: {
											alignSelf: 'flex-end',
										},
									}}
									size="md"
								/>
							</View>

							{storedVoucher && (
								<View
									style={{
										marginTop: 10,
										padding: 10,
										backgroundColor: palettes.green[4],
										borderRadius: 5,
									}}
								>
									<View
										style={{
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												color: palettes.dark[0],
												fontWeight: '500',
											}}
										>
											Voucher applied:{' '}
											{storedVoucher.description}
										</Text>
										<Pressable
											onPress={() =>
												dispatch(clearVoucher())
											}
										>
											<Text
												style={{
													color: palettes.red[0],
													fontWeight: '500',
												}}
											>
												✕
											</Text>
										</Pressable>
									</View>
									<Text style={{ color: palettes.dark[0] }}>
										Discount:{' '}
										{storedVoucher.discountPercentage}%
									</Text>
									{amountToBePaid && (
										<View
											style={{
												flexDirection: 'column',
												marginTop: 5,
											}}
										>
											<View
												style={{
													flexDirection: 'row',
													justifyContent:
														'space-between',
												}}
											>
												<Text
													style={{
														color: palettes.dark[0],
													}}
												>
													Subtotal:
												</Text>
												<Text
													style={{
														color: palettes.dark[0],
														textDecorationLine:
															'line-through',
													}}
												>
													{getSymbolFromCurrency(
														findMyCurrency?.currency ||
															'EUR'
													)}
													{(
														(amountToBePaid -
															(deliveryFee ||
																0)) *
														(findMyCountry?.exchangeRate ||
															1)
													).toFixed(2)}
												</Text>
											</View>
											<View
												style={{
													flexDirection: 'row',
													justifyContent:
														'space-between',
												}}
											>
												<Text
													style={{
														color: palettes.dark[0],
													}}
												>
													After discount:
												</Text>
												<Text
													style={{
														color: palettes.dark[0],
													}}
												>
													{getSymbolFromCurrency(
														findMyCurrency?.currency ||
															'EUR'
													)}
													{(
														(amountToBePaid -
															(deliveryFee ||
																0)) *
														(1 -
															storedVoucher.discountPercentage /
																100) *
														(findMyCountry?.exchangeRate ||
															1)
													).toFixed(2)}
												</Text>
											</View>
											<View
												style={{
													flexDirection: 'row',
													justifyContent:
														'space-between',
												}}
											>
												<Text
													style={{
														color: palettes.dark[0],
													}}
												>
													Delivery fee:
												</Text>
												<Text
													style={{
														color: palettes.dark[0],
													}}
												>
													{getSymbolFromCurrency(
														findMyCurrency?.currency ||
															'EUR'
													)}
													{(
														(deliveryFee || 0) *
														(findMyCountry?.exchangeRate ||
															1)
													).toFixed(2)}
												</Text>
											</View>
											<View
												style={{
													flexDirection: 'row',
													justifyContent:
														'space-between',
													marginTop: 5,
													borderTopWidth: 1,
													borderTopColor:
														palettes.dark[0],
													paddingTop: 5,
												}}
											>
												<Text
													style={{
														color: palettes.dark[0],
														fontWeight: '700',
													}}
												>
													Total:
												</Text>
												<Text
													style={{
														color: palettes.dark[0],
														fontWeight: '700',
													}}
												>
													{getSymbolFromCurrency(
														findMyCurrency?.currency ||
															'EUR'
													)}
													{displayedDiscountedAmount
														? (
																displayedDiscountedAmount *
																(findMyCountry?.exchangeRate ||
																	1)
														  ).toFixed(2)
														: '0.00'}
												</Text>
											</View>
										</View>
									)}
								</View>
							)}
						</View>
					)}

					<Checkbox
						containerStyles={{
							marginTop: getResponsiveStyle(14, 'spacing'),
						}}
						hintText={`${t(
							'Accept general conditions of use and sale'
						)}*`}
						isLink
						onPressLink={() =>
							Linking.openURL('https://www.ocpus.net/cgv/')
						}
						onChange={value =>
							setIsAcceptTermsAndCondition(
								!isAcceptTermsAndCondition
							)
						}
						value={isAcceptTermsAndCondition}
					/>

					<View
						style={[
							isLessThanDesktopBase
								? { flexDirection: 'column' }
								: styles.buttonContainer,
						]}
					>
						{isLessThanDesktopBase && total && (
							<MobileBreakDown
								total={total}
								deliveryFee={deliveryFee || 0}
								amountToBePaid={amountToBePaid || 0}
								exchangeRate={findMyCountry?.exchangeRate || 1}
								currencySymbol={
									(findMyCurrency?.currency ||
										findDefaultCountry?.currency) as string
								}
								voucher={
									storedVoucher
										? {
												validatedVoucher: storedVoucher,
												discountedAmount:
													displayedDiscountedAmount,
										  }
										: undefined
								}
							/>
						)}

						{currentCountry === 'DZ' ||
						paymentMode === 'ON_DELIVERY' ? (
							<Button
								title={t('Pay on Delivery')}
								variant={
									isValid &&
									dirty &&
									isAcceptTermsAndCondition
										? 'primary'
										: 'disabled'
								}
								outerContainerProps={{
									style: [
										styleLoginButtonOuterContainer(),
										{
											width: isLessThanDesktopBase
												? '100%'
												: '35%',
										},
									] as any,
								}}
								size="lg"
								loading={createOrderLoading}
								onPress={() => {
									payOnDelivery && payOnDelivery(values);
									dispatch(
										setPayingAddressDetails({
											address: values.address,
											city: values.city,
											countery: values.countery,
											name: `${values?.firstName} ${values?.lastName}`,
											zipCode: values.zipCode,
											phoneNumber: values.phoneNumber,
											email: values?.email,
											firstName: values?.firstName,
											lastName: values?.lastName,
										})
									);
								}}
							/>
						) : (
							<Button
								title={
									isAddAddress
										? t('Save')
										: t('Proceed to Checkout')
								}
								variant={
									isValid &&
									!!values.countery &&
									isAcceptTermsAndCondition
										? 'primary'
										: 'disabled'
								}
								outerContainerProps={{
									style: [
										styleLoginButtonOuterContainer(),
										{
											width: isLessThanDesktopBase
												? '100%'
												: '35%',
										},
									],
								}}
								size="lg"
								loading={loading}
								onPress={handleSubmit}
							/>
						)}
					</View>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	countryContainer: {
		flexBasis: '100%',
		height: '50px',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		marginBottom: getResponsiveStyle(35),
		paddingLeft: getResponsiveStyle(19),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	sectionTitle: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: 'bold',
		// marginBottom: getResponsiveStyle(5),
	},
	buttonOuterContainer: {
		marginTop: getResponsiveStyle(18),
	},
});

export default AddAddressForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(18),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
