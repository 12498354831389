import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ModalComponent from '../modal/ModalComponent';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import Input from '../Input';
import Button from '../button/Button';
import { ImageContainer } from '../../create-event/event-upload/ImageContainer';
import Upload from '../upload/Upload';
import PlusIcon from '../../home/PlusIcon';
import { TStyle } from '../../../types/TStyle';
import * as yup from 'yup';
import { Formik } from 'formik';
import { DropzoneProps } from 'react-dropzone';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import useBrandCreateRequest from '../../../api/brands/useBrandCreateRequest';
import Checkbox from '../checkbox/Checkbox';
import { useAppSelector } from '../../../redux/store';

const validationSchema = yup.object({
	email: yup.string().email('Invalid email').required('Required'),
	name: yup.string().required('Required'),
	companyId: yup.string().required('Required'),
	address: yup.string().required('Required'),
	city: yup.string().required('Required'),
	zipcode: yup.string().required('Required'),
	phoneNumber: yup.string().required('Required'),
	description: yup.string().required('Required'),
});

type TInitialValue = yup.InferType<typeof validationSchema>;

const initialValues: TInitialValue = {
	email: '',
	name: '',
	companyId: '',
	address: '',
	city: '',
	zipcode: '',
	phoneNumber: '',
	description: '',
};

type Props = {
	onPress: () => void;
	types?: string | string[];
	// onConfirmDelete: () => void;
	// onConfirmCheck?: () => void;
	// checked?: boolean;
	isOpen: boolean;
	loading?: boolean;
	header?: string;
	info?: string;
};

const Title = styled.Text`
	position: relative;
	font-size: ${getResponsiveStyle(18, 'font')}px;
	font-weight: 400;
	color: #616e82;
	line-height: ${getResponsiveStyle(27, 'font')}px;
	width: max-content;
	max-width: 90%;
	// margin-bottom: ${getResponsiveStyle(60, 'spacing')}px;
`;

const BrandCreate = ({ ...props }: Props) => {
	// const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
	const [image, setImage] = useState<TMedia>();
	const [isBrandEmail, setIsBrandEmail] = useState(false);
	const primary = usePrimaryColors();

	const { setAlert } = useSetAlert();

	const { loggedInUserDetails } = useAppSelector(state => state.auth);

	const onCompletedUploadingMedia = (data: TMediaResponse) => {
		if (data?.createMedia.data && data.createMedia.success) {
			setAlert(t('Image uploaded successfully'), 'normal');
			setImage(data.createMedia.data);
		} else {
			setAlert(t('Image uploading failed'), 'danger');
		}
	};

	const { uploadMedia, loading } = useCreateMedia({
		onCompleted: onCompletedUploadingMedia,
	});

	const {
		executeBrandCreateRequest,
		loading: requestLoading,
		error,
	} = useBrandCreateRequest({
		onCompleted: data => {
			if (data.createBrandRequest?.success) {
				setAlert('Request sent successfully!', 'danger');
				props.onPress();
			}
		},
	});
	useEffect(() => {
		if (error?.message) {
			setAlert(error?.message, 'danger');
			props.onPress();
		}
	}, [error]);

	const onDrop: DropzoneProps['onDrop'] = (acceptedFiles, _, __) => {
		setAlert(t('Uploading Image'), 'warning');
		uploadMedia(acceptedFiles[0], 'EVENT', `Product Image ${Date.now}`);
	};

	return (
		<ModalComponent
			type="modal"
			header={props.header as string}
			openState={props.isOpen}
			onClose={props.onPress as any}
			isDeleteModal={true}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={values => {
					const brandRequestsArgs = {
						name: values.name,
						companyId: values.companyId,
						profileImage: image?.src,
						address: {
							address1: values.address,
							city: values.city,
							zipCode: values.zipcode,
							countryCode: 'fr',
							countryLabel: 'France',
						},
						phoneNumber: values.phoneNumber,
						email: values.email,
						description: values.description,
					};

					executeBrandCreateRequest(
						brandRequestsArgs as BrandRequestType
					);
				}}
			>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					dirty,
					isValid,
					touched,
					errors,
					values,
				}) => (
					<>
						<View style={Styles.deleteModalContent}>
							{/* <Logo
						tint={primary?.[0]}
						width={38}
						height={38}
						scale={1.3}
						viewBox="0 0 38 38"
						style={{
							marginTop: 10,
						}}
					/> */}
							<Title>Request to Create Brand.</Title>

							<View style={styleRowContainer()}>
								<Upload
									containerStyles={{
										// marginRight: getResponsiveStyle(16),
										width: getResponsiveStyle(
											128,
											'dimensions'
										),
										height: getResponsiveStyle(
											136,
											'dimensions'
										),
										// marginBottom: getResponsiveStyle(24),
									}}
									onDrop={onDrop}
									uploadIcon={
										<PlusIcon height={14} width={14} />
									}
									accept={'image/*,video/mp4'}
									hint={t('Add image')}
									loading={loading}
								/>
								{image?.src && (
									<ImageContainer
										key={image.src}
										src={image.src}
										alt={image.alt}
										containerStyles={[
											Styles.imageContainerStyles,
										]}
										id={image._id}
										onRemove={() => null}
									/>
								)}
							</View>
							<Input
								placeholder="Brand name*"
								containerStyles={{
									width: '100%',
								}}
								onChangeText={handleChange('name')}
								onBlur={handleBlur('name')}
								value={values.name}
								error={
									touched.name && errors.name
										? errors.name
										: ''
								}
							/>
							<Input
								placeholder="Company ID*"
								containerStyles={{
									width: '100%',
								}}
								onChangeText={handleChange('companyId')}
								onBlur={handleBlur('companyId')}
								value={values.companyId}
								error={
									touched.companyId && errors.companyId
										? errors.companyId
										: ''
								}
							/>
							<Input
								placeholder="Address*"
								containerStyles={{
									width: '100%',
								}}
								onChangeText={handleChange('address')}
								onBlur={handleBlur('address')}
								value={values.address}
								error={
									touched.address && errors.address
										? errors.address
										: ''
								}
							/>

							<View style={Styles.address}>
								<Input
									placeholder="City*"
									containerStyles={{
										width: '50%',
									}}
									onChangeText={handleChange('city')}
									onBlur={handleBlur('city')}
									value={values.city}
									error={
										touched.city && errors.city
											? errors.city
											: ''
									}
								/>
								<Input
									placeholder="Zip Code*"
									containerStyles={{
										width: '50%',
									}}
									onChangeText={handleChange('zipcode')}
									onBlur={handleBlur('zipcode')}
									value={values.zipcode}
									error={
										touched.zipcode && errors.zipcode
											? errors.zipcode
											: ''
									}
								/>
							</View>

							<Input
								placeholder="Phone number*"
								containerStyles={{
									width: '100%',
								}}
								onChangeText={handleChange('phoneNumber')}
								onBlur={handleBlur('phoneNumber')}
								value={values.phoneNumber}
								error={
									touched.phoneNumber && errors.phoneNumber
										? errors.phoneNumber
										: ''
								}
							/>
							<Input
								placeholder="Email*"
								containerStyles={{
									width: '100%',
								}}
								onChangeText={handleChange('email')}
								onBlur={handleBlur('email')}
								value={values.email}
								editable={!isBrandEmail}
								error={
									touched.email && errors.email
										? errors.email
										: ''
								}
							/>
							<View
								style={{
									width: '100%',
								}}
							>
								<Checkbox
									hintText={`${t('I am also the brand.')}`}
									value={isBrandEmail}
									onChange={value => {
										setIsBrandEmail(value);
										if (value) {
											setFieldValue(
												'email',
												loggedInUserDetails?.email
											);
										} else {
											setFieldValue('email', '');
										}
									}}
								/>
							</View>
							<Input
								placeholder="Description*"
								containerStyles={{
									width: '100%',
								}}
								multiline
								style={[styleDescription()]}
								onChangeText={handleChange('description')}
								onBlur={handleBlur('description')}
								value={values.description}
								error={
									touched.description && errors.description
										? errors.description
										: ''
								}
							/>
							<Button
								title={`Done`}
								loading={requestLoading}
								variant={
									dirty && isValid ? 'primary' : 'disabled'
								}
								size="md"
								outerContainerProps={{
									style: {
										width: '100%',
									},
								}}
								onPress={handleSubmit}
							/>
						</View>
					</>
				)}
			</Formik>
		</ModalComponent>
	);
};

const Styles = StyleSheet.create({
	deleteModalContent: {
		// marginBottom: getResponsiveStyle(20),
		// paddingBottom: getResponsiveStyle(20),
		color: palettes.grey[0],
		width: '100%',
		// borderBottomColor: palettes.grey[5],
		// borderBottomWidth: 1,
		// justifyContent: "center",
		alignItems: 'center',
	},
	address: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	deleteModalActionBtn: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	imageContainerStyles: {
		minWidth: 144,
		flex: 1,
	},
});

function styleDescription() {
	return {
		height: getResponsiveStyle(80, 'dimensions'),
		paddingVertical: 20,
	};
}

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: getResponsiveStyle(10),
		width: '100%',
		// marginRight: getResponsiveStyle(-16),
	} as TStyle;
};

export default BrandCreate;
