import { gql } from '@apollo/client';

export const CREATE_VOUCHER = gql`
	mutation createVoucher($args: CreateVoucherInputs!) {
		createVoucher(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UPDATE_VOUCHER = gql`
	mutation updateVoucher($args: UpdateVoucherInputs!) {
		updateVoucher(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const VALIDATE_VOUCHER_CODE = gql`
	mutation validateUserVoucher($args: ValidateUserVoucherInput) {
		validateUserVoucher(args: $args) {
			statusCode
			message
			success
			... on Response {
				data {
					... on UserVoucher {
						discountPercentage
						code
					}
				}
			}
		}
	}
`;
