import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	CreateEventSidebarArray,
	EditUserSidebarItems,
	SignupSidebarArray,
} from '../constants/sidebar-items';
import { getWidth } from '../utils/getWidth';

const primaryColors = [
	['#1C1C1C', '#F96152', '#ffe7db', '#F96152B2', '#F9615266', '#F9615233'], // Consumer
	['#1C1C1C', '#12BCF3', '#c5f0ff', '#12BCF3B2', '#12BCF366', '#12BCF333'], // Brand
	['#1C1C1C', '#FEBF3B', '#ffebba', '#FEBF3BB2', '#FEBF3B66', '#FEBF3B33'], // Host
	['#1C1C1C', '#6645F4', '#E0DAFD', '#6645F4B2', '#6645F466', '#6645F433'], // Seller
];

type TTYPE_OF_ACCOUNT_INDEX = {
	[k in TTypeOfAccount]: number;
};

type TInitialState = {
	width: number;
	screen: string;
	country: string;
	language: 'fr' | 'en';
	SignupSidebarArray: TSidebarArrayItem[];
	CreateEventSidebarArray: TSidebarArrayItem[];
	EditUserSidebarItems: TSidebarArrayItem[];
	alerts: TError[];
	primaryColors: string[];
	indexOfPrimaryColors: number;
	globalLoading: boolean;
	noOfNotifications: number;
	isVisitingAddHostScreenFromEventsScreen: boolean;
	isInviteAllGuest: boolean;
	isInviteAllGuestUsers: boolean;
	isInviteAllHostUsers: boolean;
	isInviteAllHost: boolean;
	isSocialAuth: boolean;
};

const TYPE_OF_ACCOUNT_INDEX: TTYPE_OF_ACCOUNT_INDEX = {
	CONSUMER: 0,
	BRAND: 1,
	HOST: 2,
	SELLER: 3,
	SUPER_ADMIN: 4,
};

const initialState: TInitialState = {
	width: getWidth(),
	screen: '',
	country: '',
	language: 'fr',
	SignupSidebarArray,
	CreateEventSidebarArray,
	EditUserSidebarItems,
	alerts: [],
	primaryColors: primaryColors[0],
	indexOfPrimaryColors: 0,
	globalLoading: false,
	noOfNotifications: 0,
	isVisitingAddHostScreenFromEventsScreen: false,
	isInviteAllGuest: false,
	isInviteAllGuestUsers: false,
	isInviteAllHost: false,
	isInviteAllHostUsers: false,
	isSocialAuth: false,
};

// negative value means skip nothing
const SignupIdsToOmitForUserType = {
	SELLER: [2, 3, 4, 5, 7, 6],
	CONSUMER: [2, 3, 4, 5, 6, 7],
	HOST: [2, 4, 5, 6, 7],
	SUPER_ADMIN: [6],
	BRAND: [6],
};

// based on event type
const CreateEventIdsToOmit = {
	PHYSICAL: [2, 5],
	VIRTUAL: [2, 3, 7],
};

const EditUserIdsToOmit = {
	SELLER: [3],
	CONSUMER: [1, 3],
	HOST: [1, 3],
	SUPER_ADMIN: [1, 3],
	BRAND: [1, 3],
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setScreenWidth(state, action: PayloadAction<number>) {
			state.width = action.payload;
		},
		setCurrentScreen(state, action: PayloadAction<string>) {
			state.screen = action.payload;
		},
		setCountry(state, action: PayloadAction<string>) {
			state.country = action.payload;
		},
		setIsSocialAuth(state, action: PayloadAction<boolean>) {
			state.isSocialAuth = action.payload;
		},
		setSignupSidebarValues(state, action: PayloadAction<TTypeOfAccount>) {
			const filteredSignupSidebar = SignupSidebarArray.filter(
				item =>
					!SignupIdsToOmitForUserType[action.payload].includes(
						item.id
					)
			);
			state.SignupSidebarArray = filteredSignupSidebar;
		},
		setCreateEventSidebarValues(
			state,
			action: PayloadAction<TTypeOfEvent>
		) {
			const filteredCreateEventSidebar = CreateEventSidebarArray.filter(
				item => !CreateEventIdsToOmit[action.payload].includes(item.id)
			);
			state.CreateEventSidebarArray = filteredCreateEventSidebar;
		},
		setEditUserSidebarItems(state, action: PayloadAction<TTypeOfAccount>) {
			const filteredSignupSidebar = EditUserSidebarItems.filter(
				item => !EditUserIdsToOmit[action.payload].includes(item.id)
			);
			state.EditUserSidebarItems = filteredSignupSidebar;
		},
		setIsInviteAllGuest(state, action: PayloadAction<boolean>) {
			state.isInviteAllGuest = action.payload;
		},
		setIsInviteAllGuestUsers(state, action: PayloadAction<boolean>) {
			state.isInviteAllGuestUsers = action.payload;
		},
		setIsInviteAllHost(state, action: PayloadAction<boolean>) {
			state.isInviteAllHost = action.payload;
		},
		setIsInviteAllHostUsers(state, action: PayloadAction<boolean>) {
			state.isInviteAllHostUsers = action.payload;
		},
		setLanguage(state, action: PayloadAction<TLanguages>) {
			state.language = action.payload;
		},
		setAlerts(state, action: PayloadAction<TError[]>) {
			state.alerts = action.payload;
		},
		setPrimaryColors(state, action: PayloadAction<TTypeOfAccount>) {
			const indexOfPrimaryColors = TYPE_OF_ACCOUNT_INDEX[action.payload];
			state.indexOfPrimaryColors = indexOfPrimaryColors;
			state.primaryColors = primaryColors[indexOfPrimaryColors];
		},
		setGlobalLoading(state, action: PayloadAction<boolean>) {
			state.globalLoading = action.payload;
		},
		setNoOfNotifications(state, action: PayloadAction<number>) {
			state.noOfNotifications = action.payload;
		},
		setIsVisitingAddHostScreenFromEventsScreen(
			state,
			action: PayloadAction<boolean>
		) {
			state.isVisitingAddHostScreenFromEventsScreen = action.payload;
		},
		resetState() {},
	},
});

export const {
	setScreenWidth,
	setCurrentScreen,
	setSignupSidebarValues,
	setCreateEventSidebarValues,
	setEditUserSidebarItems,
	setLanguage,
	setCountry,
	setAlerts,
	setPrimaryColors,
	resetState,
	setGlobalLoading,
	setNoOfNotifications,
	setIsVisitingAddHostScreenFromEventsScreen,
	setIsInviteAllGuest,
	setIsInviteAllGuestUsers,
	setIsInviteAllHost,
	setIsInviteAllHostUsers,
	setIsSocialAuth,
} = uiSlice.actions;

export default uiSlice.reducer;
