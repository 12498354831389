import { useQuery } from '@apollo/client';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Loader } from '../components';
import CartList from '../components/common/desktop-navigation-bar/Cart-List/CartList';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import AddressSection from '../components/profile/Profile-Address-Book/AddressSection';
import { TCard } from '../components/profile/Profile-Wallet/CardSection';
import { palettes } from '../config';
import { LIST_CARDS } from '../graphql/stripe/queries';
import { GET_ALL_VOUCHERs } from '../graphql/vouchers/queries';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { setloadingCards, setUserCards } from '../redux/getCardsSlice';
import { setVoucherLoading } from '../redux/loaderSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setWasCardUsed } from '../redux/userSlice';
import {
	SelectedVoucher,
	setSelectedCardFn,
	setSelectedVoucher,
	setVouchers,
} from '../redux/walletSlice';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';
import { twoDp } from '../utils/twoDP';
import { useGetCartItems } from '../hooks/handleCheckout/useGetCartItems';
import { useCheckoutCommon } from '../hooks/handleCheckout/useCheckoutCommon';
import { useCreateOrder } from '../hooks/handleCheckout/useCreateOrder';
import { useBrandAllowedCurrency } from '../hooks/useBrandAllowedCurrency';

const CheckoutAddressScreen = () => {
	const dispatch = useAppDispatch();
	const { shouldHideScreen } = useHandleUnmount();

	useEffect(() => {
		dispatch(setCurrentScreen('/checkout-address'));
	}, []);

	useEffect(() => {
		dispatch(setWasCardUsed(false));
		dispatch(setSelectedVoucher({} as SelectedVoucher));
		dispatch(setSelectedCardFn({} as TCard));
	}, []);

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);

	const { t } = useTranslation();
	const { loading: CardsLoading, data: cardsListed } = useQuery(LIST_CARDS, {
		variables: {
			args: {
				id: stripeId_,
			},
		},
		onCompleted: res => {
			dispatch(setUserCards(res.listCards.data.data));
		},
	});

	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		state => state.auth
	);
	const { validatedVoucher, discountedAmount: storedDiscountedAmount } =
		useAppSelector(state => state.voucher);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	const { data: vouchersData, loading: gettingVoucher } = useQuery(
		GET_ALL_VOUCHERs,
		{
			onCompleted: () => {
				dispatch(setVouchers(vouchersData?.getAllVouchers?.data?.data));
				dispatch(setVoucherLoading(false));
			},
		}
	);

	const { items, brandInCartIds } = useGetCartItems();

	const {
		Total,
		amountToBePaid,
		deliveryFee,
		paymentMode,
		maxOrderForDeliveryFee,
		onChangeWilaya,
		onChangeCountry,
	} = useCheckoutCommon({ brandInCartIds });

	const { createOrderLoading, payOnDelivery } = useCreateOrder({
		items,
		Total,
		deliveryFee,
		brandInCartIds,
	});
	const onlyBrandInCart = usersCart?.[0]?.eventId?.affiliatedBrands?.[0];

	const { findDefaultCountry, findMyCountry, findMyCurrency } =
		useBrandAllowedCurrency({ brand: onlyBrandInCart });

	// useEffect(() => {
	// 	if (
	// 		(chargingData?.chargeCustomer?.success === true &&
	// 			chargingData?.hargeCustomer?.statusCode === 200) ||
	// 		voucherPaymentSuccessful ||
	// 		(chargingWithoutCardData?.chargeCustomerWithoutSavingCard
	// 			?.success === true &&
	// 			chargingWithoutCardData?.chargeCustomerWithoutSavingCard
	// 				?.statusCode === 200)
	// 	) {
	// 		setAlert(t('Payment successful'), 'normal');
	// 		let vouchersUsedToPay: TODO[] = [];
	// 		Object.keys(payingVouchers).map(id => {
	// 			const voucher_ = vouchers?.find(vouch => vouch._id === id);
	// 			vouchersUsedToPay.push(voucher_);
	// 		});
	// 		dispatch(setPaymentVouchers(vouchersUsedToPay));
	// 		navigation.navigate('PurchaseComplete');
	// 	}
	// }, [chargingData, voucherPaymentSuccessful, chargingWithoutCardData]);

	useEffect(() => {
		if (CardsLoading) {
			dispatch(setloadingCards(true));
		}

		if (cardsListed) {
			dispatch(setloadingCards(false));
		}
	}, [cardsListed, CardsLoading]);

	const displayedDiscountedAmount = validatedVoucher
		? storedDiscountedAmount
		: amountToBePaid || 0;

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			{CardsLoading || gettingVoucher ? (
				<Loader />
			) : (
				<>
					<View style={styles.container}>
						<View
							style={
								!isLessThanDesktopBase
									? styles.container_1
									: { width: '100%' }
							}
						>
							<AddressSection
								onCheckout={true}
								payOnDelivery={payOnDelivery}
								onChangeWilaya={onChangeWilaya}
								onChangeCountry={onChangeCountry}
								createOrderLoading={createOrderLoading}
								paymentMode={paymentMode}
								maxOrderForDelivery={+maxOrderForDeliveryFee}
								amountToBePaid={amountToBePaid}
								total={Total}
								deliveryFee={deliveryFee}
								// setName={setAddressBookName}
								// setAddress={setAddressBookAddress}
								// setCity={setAddressBookCity}
								// setZipCode={setAddressBookZipCode}
								// setCountry={setAddressBookCountery}
							/>
							{/* {userAccountType === 'HOST' && <VoucherSection />} */}
							{/* <CardSection onCheckout="yes" /> */}
						</View>
						{!isLessThanDesktopBase && (
							<View style={styles.container_2}>
								{usersCart.length > 0 && (
									<>
										<Text style={styles.headerText}>
											{/* {t('All Products')}(
											{usersCart.length < 10
												? `0${usersCart.length}`
												: usersCart.length}
											) */}
											{t('My cart')}
										</Text>
										<CartList onCheckout />
									</>
								)}

								<View
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<View style={styles.breakDown}>
										<Text style={styles.subTotalText}>
											{t('Delivery Fee')}:
										</Text>
										<Text
											style={[
												styles.subtotal,
												{
													fontSize:
														getResponsiveStyle(11),
												},
											]}
										>
											{getSymbolFromCurrency(
												(findMyCurrency?.currency ||
													findDefaultCountry?.currency) as string
											)}
											{/* {Total > maxOrderForDeliveryFee
												? 0
												: deliveryFee *
												(findCurrencyAndRate?.rate ||
													1)} */}
											{deliveryFee *
												(findMyCountry?.exchangeRate ||
													1)}
										</Text>
									</View>

									{validatedVoucher && (
										<View style={styles.breakDown}>
											<Text
												style={[
													styles.subTotalText,
													{
														color: palettes
															.green[0],
													},
												]}
											>
												Voucher discount (
												{
													validatedVoucher.discountPercentage
												}
												%):
											</Text>
											<Text
												style={[
													styles.subtotal,
													{
														fontSize:
															getResponsiveStyle(
																11
															),
														color: palettes
															.green[0],
													},
												]}
											>
												-
												{getSymbolFromCurrency(
													(findMyCurrency?.currency ||
														findDefaultCountry?.currency) as string
												)}
												{twoDp(
													((Total *
														validatedVoucher.discountPercentage) /
														100) *
														(findMyCountry?.exchangeRate ||
															1)
												)}
											</Text>
										</View>
									)}

									<View style={styles.total}>
										<Text style={styles.subTotalText}>
											{isLessThanDesktopBase
												? ''
												: t('Total')}
										</Text>
										<Text style={styles.subtotal}>
											{isLessThanDesktopBase
												? ''
												: `${getSymbolFromCurrency(
														(findMyCurrency?.currency ||
															findDefaultCountry?.currency) as string
												  )}${twoDp(
														(displayedDiscountedAmount as number) *
															(+findMyCountry?.exchangeRate ||
																1)
												  )}`}
										</Text>
									</View>
								</View>
							</View>
						)}
					</View>

					<View
						style={
							!isLessThanDesktopBase
								? {
										display: 'flex',
										flexDirection: 'row',
										marginTop: 40,
										marginBottom: 20,
								  }
								: {
										display: 'flex',
										flexDirection: 'row',
										marginTop: 20,
										marginBottom: 100,
										justifyContent: 'center',
								  }
						}
					></View>
				</>
			)}
		</DesktopTemplate>
	);
};

export default CheckoutAddressScreen;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	total: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginTop: '15px',
		paddingTop: '15px',
		justifyContent: 'space-between',
		// marginLeft: '5%',
		borderTopColor: palettes.grey[5],
		height: 1,
		borderTopWidth: 1,
	},
	container_1: {
		width: '57%',
	},
	container_2: {
		borderLeftWidth: 1,
		borderLeftColor: 'whitesmoke',
		// marginRight: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(17),
		width: '40%',
	},
	subtotal: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		color: palettes.dark[2],
		fontWeight: 'bold',
	},
	subTotalText: {
		fontSize: getResponsiveStyle(11),
		lineHeight: getResponsiveStyle(18),
		color: palettes.grey[1],
	},
	breakDown: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(14, 'font'),
	},
	confirm: {
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		borderRadius: 5,
		marginTop: 10,
	},
});
