import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	DefaultOptions,
	InMemoryCache,
	split,
	useQuery,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { NavigationContainer } from '@react-navigation/native';
import { createUploadLink } from 'apollo-upload-client';
import { StatusBar } from 'expo-status-bar';
// import { Head } from 'expo-head';
import { createClient } from 'graphql-ws';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Helmet } from 'react-helmet';
import FacebookPixel from 'react-facebook-pixel';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import HTML from 'react-native-render-html';
import { useGetUser } from './app/api/auth';
import { Loader } from './app/components';
import Alerts from './app/components/common/alerts/alerts';
import { useFonts } from './app/hooks/useCutomFonts';
import { useIsSpecificViewportWidth } from './app/hooks/useIsSpecificViewportWidth';
import './app/i18n/i18n';
import {
	setIsUserLoggedIn,
	setLoggedInUserDetails,
} from './app/redux/authSlice';
import store, { useAppDispatch, useAppSelector } from './app/redux/store';
import './index.web.css';
import {
	setAlerts,
	setCountry,
	setNoOfNotifications,
	setPrimaryColors,
	setScreenWidth,
} from './app/redux/uiSlice';
import { linking } from './navigation/Linking';
import { ScreenConfig } from './navigation/ScreenConfig';
import { Stack } from './navigation/Stack';
import { FACEBOOK_CLIENT_ID, FACEBOOK_CONFIG_ID, GOOGLE_CLIENT_ID } from './app/constants/KEYS';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { useGetCart } from './app/hooks/useGetCart';
import { useGetStripeId } from './app/hooks/useGetStripeId';
import { useGetTags } from './app/api/config/useGetTags';
import { onCompletedTag } from './app/api/config/onCompleted';
import { setTypeOfAccount } from './app/redux/createUserSlice';
// import io from 'socket.io-client';
// import ENVS from 'react-native-dotenv';
import config from './config';
import { NOTIFICATION_SUBSCRIPTION } from './app/graphql/notification/subscription';
import { GET_ALL_NOTIFICATIONS } from './app/graphql/notification/queries';
import axios from 'axios';
const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'network-only',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
	mutate: {
		errorPolicy: 'all',
	},
};

const LINK = {
	credentials: 'include',
	uri: config.SERVER_URL,
	// uri: "http://localhost:3030/graphql",
	// uri: 'https://ocpserdev.punchapps.cool/graphql',
	// uri: 'https://server.ocpus.io/graphql',
	// uri: 'https://ocpus-server-staging.herokuapp.com/graphql',
	// uri: 'https://ocpsersta.punchapps.cool/graphql',
	// uri: 'https://ocpus-sever-development.herokuapp.com/graphql',
};

const uploadLink = createUploadLink(LINK);
// const httpLink = createHttpLink(LINK);
const wsLink = new GraphQLWsLink(
	createClient({
		url: config.SERVER_SOCKET_URL,
		// url: 'ws://localhost:3030/graphql',
		// url: 'wss://server.ocpus.io/graphql',
		// url: 'https://ocpus-server-staging.herokuapp.com/graphql',
		// url: 'wss://ocpus-sever-development.herokuapp.com/graphql',
	})
);

// 631b0c369d224a2222db22a2
// 63402723b661612bd4bf660c
// 63257617bbbd628ee373ceec
/* 
* Ideally, this should be used to detect 401, however, because of the
* way BE is setup, it always returns 200 status code and has real
* status codes in the response data. Hence this approach does not work

const logoutLink = onError(({ networkError, graphQLErrors }) => {
	  if ((networkError as any)?.statusCode === 401) {
			store.dispatch(setIsUserLoggedIn(false));
	  }
});
*/

/*
 * Logouts user after detecting 401 status code in response data
 * Note: Not ideal but this is the approach that works
 */

const logoutLink = new ApolloLink((operation, forward) => {
	return forward(operation).map((response: any) => {
		// O(n) but because the function name is one step deep, performance
		// does not take a hit (i think)
		for (const key of Object.keys(response?.data)) {
			const error = response?.data?.[key]?.statusCode;
			const error1 = response?.errors?.[0]?.message;

			if (error === 401 && key !== 'getUser') {
				// console.log(error, error1, key);
				store.dispatch(setIsUserLoggedIn(false));
				// store.dispatch(
				// 	setAlerts([
				// 		{
				// 			title: 'Session expired! Please login again.',
				// 			type: 'danger',
				// 		},
				// 	])
				// );
			} else if (error1 == 'Unauthorized') {
				store.dispatch(setIsUserLoggedIn(false));
			}
		}
		return response;
	});
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	logoutLink.concat(uploadLink)
);

const client = new ApolloClient({
	link: splitLink,
	cache: new InMemoryCache(),
	defaultOptions,
});

// export const socket = io(config.SOCKET_SERVER, {
// 	// path: '/my-custom-path/',
// });

const pixelId = '598615668988743';
// const MyHelmet = () => {
// 	const [reloadHelmet, setHelmetReload] = useState('');
// 	const { screen } = useAppSelector(state => state.ui);
// 	useEffect(() => {
// 		setHelmetReload(screen);
// 	}, [screen]);
// 	return (
// 		<Helmet>
// 			<meta
// 				name="facebook-domain-verification"
// 				content="21ybdrpwo82huqsghbnswbm5bsnnk8"
// 			/>
// 			<script>
// 				{`
// 	  window.dataLayer = window.dataLayer || [];
// 	  function gtag(){dataLayer.push(arguments);}
// 	  gtag('js', new Date());

// 	  gtag('config', 'G-6EYEJ58WNV');
// 	`}
// 			</script>
// 			<script>
// 				{`
// 		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// 		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// 		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// 		})(window,document,'script','dataLayer','GTM-MV9V6GW');
// 		`}
// 			</script>
// 			<script
// 				async
// 				src="https://www.googletagmanager.com/gtag/js?id=G-6EYEJ58WNV"
// 			></script>
// 			<script>
// 				{`
// 	  !function(f,b,e,v,n,t,s)
// 	  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
// 	  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
// 	  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
// 	  n.queue=[];t=b.createElement(e);t.async=!0;
// 	  t.src=v;s=b.getElementsByTagName(e)[0];
// 	  s.parentNode.insertBefore(t,s)}(window, document,'script',
// 	  'https://connect.facebook.net/en_US/fbevents.js');
// 	  fbq('init', '598615668988743');
// 	  fbq('track', 'PageView');
// 	`}
// 			</script>
// 		</Helmet>
// 	);
// };

export default function App() {
	let persistor = persistStore(store);
	FacebookPixel.init(pixelId);
	FacebookPixel.pageView();
	return (
		<RecoilRoot>
			<ApolloProvider client={client}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
							{/* <MyHelmet /> */}
							<Main />
						</GoogleOAuthProvider>
					</PersistGate>
				</Provider>
			</ApolloProvider>
		</RecoilRoot>
	);
}

function Main() {
	const { width } = useIsSpecificViewportWidth();
	const dispatch = useAppDispatch();
	const { getAppTags, data } = useGetTags();
	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		state => state.auth
	);
	const { subscribeToMore: subscribeToNotification, data: notificationData } =
		useQuery(GET_ALL_NOTIFICATIONS);

	useEffect(() => {
		// Load the Facebook SDK script
		if (!window) return;
		(function (d, s, id) {
			const fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			const js = d.createElement(s);
			js.id = id;
			(js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
			(fjs as any).parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
		// Initialize the Facebook SDK after it has loaded
		(window as any).fbAsyncInit = () => {
			(window as any).FB.init({
				appId: FACEBOOK_CLIENT_ID, // Replace with your Facebook App ID
				cookie: true,
				xfbml: true,
				version: 'v20.0',
				config_id: FACEBOOK_CONFIG_ID, // Replace with your config_id
			});
		};
	}, []);
	useEffect(() => {
		let noOfUnread = 0;
		if (notificationData !== undefined) {
			notificationData?.getAllNotifications?.data?.data?.map(
				(data: NotificationType) => {
					if (data.read === false) {
						noOfUnread += 1;
					}
				}
			);
		}

		dispatch(setNoOfNotifications(noOfUnread));
	}, [notificationData]);

	subscribeToNotification({
		document: NOTIFICATION_SUBSCRIPTION,
		variables: { userId: loggedInUserDetails?._id },
		updateQuery: (prev, { subscriptionData }) => {
			if (!subscriptionData.data) return prev;
			const newNotification =
				subscriptionData.data.notificationSubscription;
			let oldObj = {};
			if (
				!!prev &&
				!!prev?.getAllNotifications &&
				!!prev?.getAllNotifications?.data &&
				!!prev?.getAllNotifications?.data?.data
			) {
				oldObj = { ...prev.getAllNotifications.data.data };
			}
			// if (prev !== undefined) {
			// 	if (prev.getAllNotifications !== undefined) {
			// 		if (prev?.getAllNotifications?.data !== undefined) {
			// 			if (prev.getAllNotifications?.data?.data !== undefined) {
			// 				oldObj = { ...prev.getAllNotifications.data.data };
			// 			}
			// 		}
			// 	}
			// }
			return {
				getAllNotifications: {
					data: {
						data: [newNotification, oldObj],
					},
				},
			};
		},
	});

	const { loading } = useGetUser();

	useEffect(() => {
		dispatch(setScreenWidth(width));
		useFonts().then(() => null);
	}, [width]);

	const { getCart } = useGetCart();
	const { getLoggedInUserStripeId } = useGetStripeId();
	useEffect(() => {
		getAppTags({ onCompleted: data => onCompletedTag(data, dispatch) });
	}, [isUserLoggedIn]);

	const allowedTypeOfAccount = ['CONSUMER', 'SELLER', 'HOST'];

	useEffect(() => {
		if (
			!allowedTypeOfAccount.includes(
				loggedInUserDetails?.typeOfAccount as TTypeOfAccount
			)
		) {
			dispatch(setTypeOfAccount('CONSUMER'));
		}
		getCart();
		getLoggedInUserStripeId();
	}, [isUserLoggedIn]);

	useEffect(() => {
		if (
			!isUserLoggedIn
		) {
			dispatch(setPrimaryColors('CONSUMER'));
		}
	}, [])

	useEffect(() => {
		// TODO: refactor this for caching
		const fetchCountry = async () => {
			try {
				const response = await axios.get("https://ipwhois.app/json/");
				dispatch(setCountry(response?.data?.country_code));
			} catch (error) {
				console.error("Error fetching location:", error);
			}
		};

		fetchCountry();
	}, []);

	return (
		<View style={styles.container}>
			{/* <View style={{ position: 'absolute' }}>
				<HTML source={{ uri: './index.html' }} />
			</View> */}
			<Alerts />
			{loading && isUserLoggedIn === 'loading' ? (
				<Loader fullView />
			) : (
				<NavigationContainer linking={linking}>
					<Stack.Navigator>
						<>
							{isUserLoggedIn && isUserLoggedIn !== 'loading' ? (
								<>
									{/* TODO: Refactor code */}
									{/* Protected Route */}
									{ScreenConfig.filter(
										({ protectedRoute = true }) =>
											protectedRoute === true
									).map(
										({
											protectedRoute = true,
											...args
										}) => (
											<Stack.Screen
												key={args.name}
												{...args}
											/>
										)
									)}
								</>
							) : (
								<>
									{/* UnProtected Route */}
									{ScreenConfig.filter(
										({ protectedRoute = true }) =>
											protectedRoute === false
									).map(
										({
											protectedRoute = true,
											...args
										}) => (
											<Stack.Screen
												key={args.name}
												{...args}
											/>
										)
									)}
								</>
							)}
						</>
					</Stack.Navigator>
					<StatusBar style="auto" />
				</NavigationContainer>
			)}
			{/* <noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-T9S5PW"
					height="0"
					width="0"
					// style="display:none;visibility:hidden"
					style={{
						display: 'none',
						visibility: 'hidden',
					}}
				></iframe>
			</noscript>
			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-MV9V6GW"
					height="0"
					width="0"
					// style="display:none;visibility:hidden"
					style={{
						display: 'none',
						visibility: 'hidden',
					}}
				></iframe>
			</noscript> */}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: '#fff',
		overflowX: 'hidden',
	},
});
